@import "_colours.scss";

.message {
    padding: 0.75rem 0;
    text-align: center;
    clear: both;

    p {
        margin: 0 auto;
        color: $white;
    }

    &.negative {
        background: $negative;
    }

    &.positive {
        background: $positive;
    }
}

span.error {
    float: left;
    display: block;
    background: $negative;
    width: 100%;
    margin: 0 0 1rem;
    padding: 0.4rem 0.65rem;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    font-size: 80%;
    color: $white;

    &:empty {
        display: none;
    }
}

p.error {
    text-align: center;
    color: $negative;
}
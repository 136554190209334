@font-face {
  font-family: 'Montserrat';
  src: url("/fonts/Montserrat/black/montserrat-black.eot?v=1.1.0");
  src: url("/fonts/Montserrat/black/montserrat-black.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/fonts/Montserrat/black/montserrat-black.woff2?v=1.1.0") format("woff2"), url("/fonts/Montserrat/black/montserrat-black.woff?v=1.1.0") format("woff"), url("/fonts/Montserrat/black/montserrat-black.ttf?v=1.1.0") format("truetype"), url("/fonts/Montserrat/black/montserrat-black.svg?v=1.1.0#black") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("/fonts/Montserrat/extrabold/montserrat-extrabold.eot?v=1.1.0");
  src: url("/fonts/Montserrat/extrabold/montserrat-extrabold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/fonts/Montserrat/extrabold/montserrat-extrabold.woff2?v=1.1.0") format("woff2"), url("/fonts/Montserrat/extrabold/montserrat-extrabold.woff?v=1.1.0") format("woff"), url("/fonts/Montserrat/extrabold/montserrat-extrabold.ttf?v=1.1.0") format("truetype"), url("/fonts/Montserrat/extrabold/montserrat-extrabold.svg?v=1.1.0#extrabold") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("/fonts/Montserrat/bold/montserrat-bold.eot?v=1.1.0");
  src: url("/fonts/Montserrat/bold/montserrat-bold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/fonts/Montserrat/bold/montserrat-bold.woff2?v=1.1.0") format("woff2"), url("/fonts/Montserrat/bold/montserrat-bold.woff?v=1.1.0") format("woff"), url("/fonts/Montserrat/bold/montserrat-bold.ttf?v=1.1.0") format("truetype"), url("/fonts/Montserrat/bold/montserrat-bold.svg?v=1.1.0#bold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("/fonts/Montserrat/semibold/montserrat-semibold.eot?v=1.1.0");
  src: url("/fonts/Montserrat/semibold/montserrat-semibold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/fonts/Montserrat/semibold/montserrat-semibold.woff2?v=1.1.0") format("woff2"), url("/fonts/Montserrat/semibold/montserrat-semibold.woff?v=1.1.0") format("woff"), url("/fonts/Montserrat/semibold/montserrat-semibold.ttf?v=1.1.0") format("truetype"), url("/fonts/Montserrat/semibold/montserrat-semibold.svg?v=1.1.0#semibold") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("/fonts/Montserrat/medium/montserrat-medium.eot?v=1.1.0");
  src: url("/fonts/Montserrat/medium/montserrat-medium.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/fonts/Montserrat/medium/montserrat-medium.woff2?v=1.1.0") format("woff2"), url("/fonts/Montserrat/medium/montserrat-medium.woff?v=1.1.0") format("woff"), url("/fonts/Montserrat/medium/montserrat-medium.ttf?v=1.1.0") format("truetype"), url("/fonts/Montserrat/medium/montserrat-medium.svg?v=1.1.0#medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("/fonts/Montserrat/regular/montserrat-regular.eot?v=1.1.0");
  src: url("/fonts/Montserrat/regular/montserrat-regular.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/fonts/Montserrat/regular/montserrat-regular.woff2?v=1.1.0") format("woff2"), url("/fonts/Montserrat/regular/montserrat-regular.woff?v=1.1.0") format("woff"), url("/fonts/Montserrat/regular/montserrat-regular.ttf?v=1.1.0") format("truetype"), url("/fonts/Montserrat/regular/montserrat-regular.svg?v=1.1.0#regular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("/fonts/Montserrat/light/montserrat-light.eot?v=1.1.0");
  src: url("/fonts/Montserrat/light/montserrat-light.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/fonts/Montserrat/light/montserrat-light.woff2?v=1.1.0") format("woff2"), url("/fonts/Montserrat/light/montserrat-light.woff?v=1.1.0") format("woff"), url("/fonts/Montserrat/light/montserrat-light.ttf?v=1.1.0") format("truetype"), url("/fonts/Montserrat/light/montserrat-light.svg?v=1.1.0#light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("/fonts/Montserrat/extralight/montserrat-extralight.eot?v=1.1.0");
  src: url("/fonts/Montserrat/extralight/montserrat-extralight.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/fonts/Montserrat/extralight/montserrat-extralight.woff2?v=1.1.0") format("woff2"), url("/fonts/Montserrat/extralight/montserrat-extralight.woff?v=1.1.0") format("woff"), url("/fonts/Montserrat/extralight/montserrat-extralight.ttf?v=1.1.0") format("truetype"), url("/fonts/Montserrat/extralight/montserrat-extralight.svg?v=1.1.0#extralight") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("/fonts/Montserrat/thin/montserrat-thin.eot?v=1.1.0");
  src: url("/fonts/Montserrat/thin/montserrat-thin.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/fonts/Montserrat/thin/montserrat-thin.woff2?v=1.1.0") format("woff2"), url("/fonts/Montserrat/thin/montserrat-thin.woff?v=1.1.0") format("woff"), url("/fonts/Montserrat/thin/montserrat-thin.ttf?v=1.1.0") format("truetype"), url("/fonts/Montserrat/thin/montserrat-thin.svg?v=1.1.0#thin") format("svg");
  font-weight: 100;
  font-style: normal; }

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  height: 2.6rem;
  padding: 0 2rem;
  color: #555;
  text-align: center;
  font-size: 0.865rem;
  font-weight: 600;
  line-height: 2.6rem;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 0.25rem;
  border: 1px solid #bbb;
  cursor: pointer;
  box-sizing: border-box; }
  .button:hover, .button:focus,
  button:hover,
  button:focus,
  input[type="submit"]:hover,
  input[type="submit"]:focus,
  input[type="reset"]:hover,
  input[type="reset"]:focus,
  input[type="button"]:hover,
  input[type="button"]:focus {
    color: #333;
    border-color: #888;
    outline: 0; }

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  height: 38px;
  padding: 6px 10px;
  /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #fff;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box; }

/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px; }

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border: 1px solid #33C3F0;
  outline: 0; }

label,
legend {
  display: block;
  margin-bottom: .5rem;
  font-weight: 600; }

fieldset {
  padding: 0;
  border-width: 0; }

input[type="checkbox"],
input[type="radio"] {
  display: inline; }

body, html {
  width: 100%;
  height: 100%;
  font-size: 16px;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  body *, html * {
    box-sizing: border-box; }

.row {
  *zoom: 1;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto; }

.row:after,
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  clear: both;
  visibility: hidden; }

.col1of2, .col2of4, .col3of6 {
  width: 50%; }

.col1of3, .col2of6 {
  width: 33.33333%; }

.col2of3, .col4of6 {
  width: 66.66666%; }

.col1of4 {
  width: 25%; }

.col3of4 {
  width: 75%; }

.col1of5 {
  width: 20%; }

.col2of5 {
  width: 40%; }

.col3of5 {
  width: 60%; }

.col4of5 {
  width: 80%; }

.col1of6 {
  width: 16.66666%; }

.col5of6 {
  width: 83.33333%; }

.col1of2,
.col1of3, .col2of3,
.col1of4, .col2of4, .col3of4,
.col1of5, .col2of5, .col3of5, .col4of5,
.col1of6, .col2of6, .col3of6, .col4of6, .col5of6 {
  float: left;
  box-sizing: border-box;
  padding: 0 0; }
  @media (max-width: 768px) {
    .col1of2,
    .col1of3, .col2of3,
    .col1of4, .col2of4, .col3of4,
    .col1of5, .col2of5, .col3of5, .col4of5,
    .col1of6, .col2of6, .col3of6, .col4of6, .col5of6 {
      float: none;
      width: 100%;
      clear: both; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  display: none;
  height: 100%;
  min-height: 1px; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

form *:focus {
  outline: none; }

form .full {
  float: left;
  width: 100%; }

form .half {
  float: left;
  width: 50%;
  padding: 0 1rem; }
  form .half:nth-of-type(2n) {
    padding-right: 0; }
  form .half:nth-of-type(2n+1) {
    padding-left: 0; }
  form .half.offset-right {
    margin-right: 50%; }
  form .half.offset-left {
    margin-left: 50%; }

form fieldset {
  margin: 0;
  padding: 0;
  border: 0 none; }
  form fieldset.radiochecks {
    padding: 0.5rem; }
    form fieldset.radiochecks label {
      float: left;
      position: relative;
      display: inline-block;
      margin-bottom: 1rem;
      padding-left: 1.75rem;
      font-weight: 300;
      clear: left; }
    form fieldset.radiochecks input {
      display: inline-block;
      position: absolute;
      left: 0;
      width: auto;
      margin: 0.25rem 0.5rem;
      clear: left; }
  form fieldset.inline label {
    float: none;
    display: inline-block; }
  form fieldset.inline input {
    float: none;
    display: inline-block;
    clear: none; }
  form fieldset.buttons {
    margin-top: 1rem; }
    form fieldset.buttons button, form fieldset.buttons .button {
      float: left;
      height: auto;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 1; }
  form fieldset legend {
    margin: 0 0 0.5rem;
    font-weight: 100;
    font-size: 150%; }

form .col1of1 {
  float: left;
  width: 100%;
  padding: 0 0.5rem; }

form .col1of2 {
  padding: 0 0.5rem; }

form label {
  float: left;
  display: block;
  margin: 0 0 0.25rem;
  padding: 0;
  text-transform: none;
  font-size: 90%;
  clear: left; }
  form label.req:after {
    content: " *"; }

form input[type] {
  float: left;
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 2px;
  font-size: 90%;
  color: #555555;
  clear: left; }
  form input[type].datepicker {
    text-align: right; }
  form input[type].error {
    margin-bottom: 0; }

form textarea {
  float: left;
  display: block;
  width: 100%;
  height: 10rem;
  margin-bottom: 2rem;
  padding: 0.8rem 0.75rem;
  border-radius: 2px;
  border: 1px solid #c2c2c2 none;
  font-size: 90%;
  color: #555555;
  clear: left; }
  form textarea.error {
    margin-bottom: 0; }

form select {
  float: left;
  display: block;
  background: #ffffff url("/img/arrow.gif") no-repeat;
  background-position: right 0.75rem top 50%;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
  padding: 0.5rem 3rem 0.5rem 0.6rem;
  text-overflow: ellipsis;
  border-radius: 2px;
  font-size: 90%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #555555;
  clear: left; }
  form select.error {
    margin-bottom: 0; }

form select::-ms-expand {
  display: none; }

form input[disabled],
form select[disabled],
form textarea[disabled] {
  border-color: #f3f3f3; }

form button,
form a.button {
  margin-right: 0.5rem;
  padding: 0.5rem 1rem; }

form.auth {
  max-width: 30rem;
  margin: 10rem auto;
  color: #232323; }
  form.auth p {
    margin: 0 0 1rem; }
  form.auth fieldset.radiochecks label {
    padding-left: 1.75rem; }
  form.auth fieldset label {
    width: 100%;
    margin: 0 0 0.25rem;
    padding: 0; }
    form.auth fieldset label[for="password"] a {
      float: right;
      margin-top: 2px;
      text-decoration: none;
      font-weight: 300;
      font-size: 80%; }
    form.auth fieldset label .forgot {
      float: right; }
  form.auth fieldset input,
  form.auth fieldset select,
  form.auth fieldset textarea {
    margin-bottom: 1rem;
    color: #232323; }
  form.auth fieldset button {
    display: inline-block;
    padding: 0.5rem 1.25rem; }
  form.auth fieldset .nav {
    float: right;
    margin: 0.5rem 1rem;
    font-size: 80%; }
  form.auth section {
    margin-bottom: 2rem;
    clear: both; }

form .formtext {
  float: left;
  width: 100%;
  margin: 0 0 2rem;
  padding: 0.75rem 0.6rem;
  text-align: left;
  font-size: 90%;
  border: 1px solid transparent; }

form#register-form {
  max-width: 600px; }

.message {
  padding: 0.75rem 0;
  text-align: center;
  clear: both; }
  .message p {
    margin: 0 auto;
    color: #ffffff; }
  .message.negative {
    background: #e64747; }
  .message.positive {
    background: #7ED648; }

span.error {
  float: left;
  display: block;
  background: #e64747;
  width: 100%;
  margin: 0 0 1rem;
  padding: 0.4rem 0.65rem;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 80%;
  color: #ffffff; }
  span.error:empty {
    display: none; }

p.error {
  text-align: center;
  color: #e64747; }

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
  clear: both; }

body {
  min-height: 100%;
  font-family: 'Montserrat';
  height: auto;
  font-weight: 300; }

a {
  color: #7ac143; }
  a:hover {
    color: #95ce69; }
  a:active {
    color: #88c756; }
  a.logo.adhesivetapes {
    display: block;
    background: url("/img/logo-adhesive-tapes.png") no-repeat center center;
    background-size: contain;
    text-indent: -999rem; }

button,
.button {
  display: block;
  background: #60c421;
  height: auto;
  padding: 0.75rem 1rem;
  letter-spacing: 0;
  border: 2px solid #60c421;
  border-radius: 0;
  line-height: 1;
  color: #ffffff; }
  button:hover, button:focus,
  .button:hover,
  .button:focus {
    background: #4b981a;
    border-color: #4b981a;
    color: #ffffff; }
  button.secondary,
  .button.secondary {
    background: #ffffff;
    color: #60c421; }
    button.secondary:hover, button.secondary:focus,
    .button.secondary:hover,
    .button.secondary:focus {
      color: #4b981a; }
  button.tertiary,
  .button.tertiary {
    background: #ffffff;
    padding: 0.8125rem 1rem;
    border: 1px solid #232323;
    color: #232323; }
    button.tertiary:hover, button.tertiary:focus,
    .button.tertiary:hover,
    .button.tertiary:focus {
      border-color: #707070;
      color: #707070; }
  button.loading,
  .button.loading {
    background-image: url("/img/spinner-loading.gif");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 80%;
    color: transparent; }
  button.disabled,
  .button.disabled {
    background-color: #c2c2c2;
    border-color: #c2c2c2;
    background-image: url("/img/spinner-loading-disabled.gif");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 80%;
    color: transparent; }
  button.slick-arrow,
  .button.slick-arrow {
    position: absolute;
    top: 50%;
    background: transparent no-repeat center center;
    background-size: contain;
    width: 1rem;
    height: 1rem;
    margin: -0.5rem 0 0 0;
    padding: 0;
    border: none;
    text-indent: -999rem; }
    button.slick-arrow.slick-prev,
    .button.slick-arrow.slick-prev {
      background-image: url("/img/arrow-left.png");
      left: -2rem; }
    button.slick-arrow.slick-next,
    .button.slick-arrow.slick-next {
      background-image: url("/img/arrow-right.png");
      right: -2rem; }
  button.loadmore,
  .button.loadmore {
    width: 10rem;
    margin: 2rem auto 0; }

h1 {
  display: inline-block;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid;
  font-size: 1.7rem;
  font-weight: 400; }

h2 {
  font-weight: 400; }

h3 {
  margin: 1rem 0;
  text-align: center;
  font-weight: 100;
  font-size: 200%; }

h4 {
  margin: 0.75rem 0 1.65rem;
  text-transform: uppercase;
  font-size: 0.865rem; }

header {
  position: relative;
  background: #ffffff; }
  header .row {
    padding: 0 1rem; }
  header .logo {
    float: left;
    width: 300px;
    height: 130px; }
  header #free-shipping {
    display: block;
    position: absolute;
    top: 1.5rem;
    right: 38.5%;
    background: #363636;
    width: 12.5rem;
    margin: 0;
    padding: 0.5rem;
    box-shadow: 0 0 0.65rem #c2c2c2;
    transform: rotate(-7.5deg);
    border: 2px solid #60c421;
    text-align: center;
    font-size: 1.2rem;
    overflow: hidden;
    color: #ffffff; }
    header #free-shipping h3 {
      margin: 0 auto;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 1.2rem; }
    header #free-shipping p {
      margin: 0 auto;
      font-size: 0.75rem;
      color: #60c421; }
      header #free-shipping p small {
        text-transform: uppercase;
        font-size: 0.5rem;
        color: #ffffff; }

nav ul {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none; }
  nav ul li {
    display: inline-block;
    width: auto;
    list-style: none;
    clear: none; }
    nav ul li a {
      display: block;
      text-transform: uppercase;
      text-decoration: none; }
  nav ul .contact-us-nav {
    background: #008000; }

nav.tools-nav, nav.tools-nav-collapse {
  float: right;
  padding-top: 3rem;
  font-size: 0.8rem; }
  nav.tools-nav ul li, nav.tools-nav-collapse ul li {
    margin: 0 0 0 1.5rem;
    line-height: 28px; }
    nav.tools-nav ul li a, nav.tools-nav-collapse ul li a {
      font-weight: 300;
      font-size: 110%;
      color: #232323; }
    nav.tools-nav ul li.search, nav.tools-nav ul li#cart-icon, nav.tools-nav ul li#account, nav.tools-nav-collapse ul li.search, nav.tools-nav-collapse ul li#cart-icon, nav.tools-nav-collapse ul li#account {
      margin: 0 0 0 0.5rem; }
      nav.tools-nav ul li.search a, nav.tools-nav ul li#cart-icon a, nav.tools-nav ul li#account a, nav.tools-nav-collapse ul li.search a, nav.tools-nav-collapse ul li#cart-icon a, nav.tools-nav-collapse ul li#account a {
        display: block;
        background: no-repeat center center;
        background-size: contain;
        width: 28px;
        height: 28px;
        text-indent: -999rem;
        text-align: left; }
    nav.tools-nav ul li.search, nav.tools-nav-collapse ul li.search {
      position: relative;
      max-width: 10rem; }
      nav.tools-nav ul li.search.active .search-term, nav.tools-nav-collapse ul li.search.active .search-term {
        width: 15rem;
        padding-right: 0.575rem;
        padding-left: 0.575rem;
        opacity: 1; }
      nav.tools-nav ul li.search.active button, nav.tools-nav-collapse ul li.search.active button {
        display: block; }
      nav.tools-nav ul li.search a, nav.tools-nav-collapse ul li.search a {
        background-image: url("/img/icon-search-black.png"); }
      nav.tools-nav ul li.search .search-term, nav.tools-nav-collapse ul li.search .search-term {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        margin: -3px 0 0 0;
        padding: 0.575rem 0;
        opacity: 0;
        transition: all 0.5s ease; }
        nav.tools-nav ul li.search .search-term:focus, nav.tools-nav-collapse ul li.search .search-term:focus {
          border-color: #232323; }
      nav.tools-nav ul li.search button, nav.tools-nav-collapse ul li.search button {
        display: none;
        position: absolute;
        top: 0;
        right: 3px;
        background: #ffffff url("/img/icon-search-black.png") no-repeat center center;
        background-size: 22px;
        width: 28px;
        height: 28px;
        margin: 0;
        padding: 3px;
        border: 0 none;
        text-indent: -999rem; }
    nav.tools-nav ul li#cart-icon a, nav.tools-nav-collapse ul li#cart-icon a {
      position: relative;
      background-image: url("/img/icon-cart-black.png"); }
      nav.tools-nav ul li#cart-icon a #cart-count, nav.tools-nav-collapse ul li#cart-icon a #cart-count {
        display: block;
        position: absolute;
        right: -1px;
        bottom: -1px;
        background: #ed1d25;
        height: 15px;
        padding: 0 3px;
        text-align: center;
        border-radius: 3px;
        font-size: 0.5rem;
        line-height: 15px;
        text-indent: 0;
        color: #ffffff; }
    nav.tools-nav ul li#account a, nav.tools-nav-collapse ul li#account a {
      background-image: url("/img/icon-account-black.svg");
      background-size: 24px; }

nav.site-nav {
  background: #60c421;
  text-align: right; }
  nav.site-nav ul li {
    padding: 18px 20px; }
    nav.site-nav ul li.home {
      float: left;
      margin: 0; }
    nav.site-nav ul li a {
      color: #ffffff; }
      nav.site-nav ul li a:hover {
        color: #363636; }

.site-nav-collapse {
  display: none;
  height: 60px; }

.sidenav {
  display: none;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  padding-top: 80px;
  background-color: #60c421;
  overflow-x: hidden;
  transition: 0.5s; }
  .sidenav a {
    padding: 20px;
    text-decoration: none;
    font-size: 1.1em;
    color: #232323;
    display: block;
    transition: 0.3s;
    text-transform: uppercase; }
  .sidenav .main-nav-collapse a:hover {
    background: #ffffff; }

.nav-open .sidenav {
  width: 100%;
  display: initial; }

.nav-collapse {
  display: none;
  position: relative;
  width: 35px;
  float: right;
  transition: all 0.5s ease; }

#nav-icon {
  width: 35px;
  height: 26px;
  position: absolute;
  top: 20px;
  right: 20px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer; }

#nav-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #60c421;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }

#nav-icon span:nth-child(1) {
  top: 0px;
  transform-origin: left center; }

#nav-icon span:nth-child(2) {
  top: 10px;
  transform-origin: left center; }

#nav-icon span:nth-child(3) {
  top: 20px;
  transform-origin: left center; }

.nav-open #nav-icon span:nth-child(1) {
  transform: rotate(45deg);
  top: -3px;
  left: 8px; }

.nav-open #nav-icon span:nth-child(2) {
  width: 0%;
  opacity: 0; }

.nav-open #nav-icon span:nth-child(3) {
  transform: rotate(-45deg);
  top: 22px;
  left: 8px; }

.sidebar {
  width: 25%; }
  .sidebar img {
    width: 120px;
    margin: 20px; }

#sidebar {
  display: inline-block; }
  #sidebar nav.categories {
    position: relative;
    background: #ffffff;
    margin-top: -1.25rem;
    padding: 2.5rem 1.25rem 1rem;
    box-shadow: 0 0 0.65rem #c2c2c2;
    border-radius: 0 0 0.125rem 0.125rem; }
    #sidebar nav.categories h4 {
      margin: 0 0 1.25rem;
      font-weight: 500; }
    #sidebar nav.categories ul {
      margin: 0;
      padding: 0;
      font-weight: 300;
      list-style: none; }
      #sidebar nav.categories ul li {
        display: block;
        margin: 0 0 1.51rem;
        font-size: 0.85rem; }
        #sidebar nav.categories ul li.active > a {
          border-color: #60c421; }
        #sidebar nav.categories ul li.active > ul {
          display: block; }
        #sidebar nav.categories ul li a {
          display: initial;
          padding: 0.25rem 0;
          border-bottom: 2px solid #ffffff;
          text-transform: uppercase;
          text-decoration: none;
          color: #232323; }
          #sidebar nav.categories ul li a:hover {
            border-color: #60c421; }
        #sidebar nav.categories ul li ul {
          display: none;
          margin-top: 1.5rem; }
          #sidebar nav.categories ul li ul.active {
            display: block; }
          #sidebar nav.categories ul li ul li {
            margin: 0 0 0.5rem 1rem; }
            #sidebar nav.categories ul li ul li a {
              text-transform: capitalize; }
              #sidebar nav.categories ul li ul li a:hover {
                border-bottom: 0 none;
                color: #60c421; }
              #sidebar nav.categories ul li ul li a.active {
                border-color: #ffffff;
                color: #60c421; }
  #sidebar .facebook {
    display: block;
    margin-top: 1rem;
    padding: 1rem 3.5rem; }
    #sidebar .facebook img {
      display: block;
      width: 100%;
      margin: 0 auto; }

.slider {
  width: 80%; }
  .slider .slick-dotted.slick-slider {
    margin-bottom: 0 !important; }

#slider .slide {
  display: -ms-flexbox;
  display: flex;
  position: relative; }
  #slider .slide .image {
    width: 100%;
    height: 496px;
    background: rgba(255, 255, 255, 0); }
    #slider .slide .image img {
      position: absolute;
      text-align: center;
      width: 100%;
      top: 50%;
      transform: translateY(-50%); }
  #slider .slide .content {
    position: absolute;
    padding: 0;
    text-align: center;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    max-width: 300px; }
    #slider .slide .content .off {
      text-align: center;
      margin: 0;
      font-size: 3em;
      color: white;
      text-transform: uppercase;
      font-weight: 500;
      text-shadow: 0px 0px 7px #555555; }
    #slider .slide .content .name {
      margin: 0;
      color: #555555;
      font-weight: 300; }
    #slider .slide .content .button {
      width: 150px;
      margin: 0 auto;
      margin-top: 20px; }
    #slider .slide .content .pricing {
      position: absolute;
      bottom: 2rem;
      width: 100%; }
      #slider .slide .content .pricing .price {
        display: inline-block;
        position: relative;
        margin: 0;
        text-transform: uppercase;
        vertical-align: bottom;
        font-size: 0.75rem;
        color: #555555;
        line-height: initial; }
        #slider .slide .content .pricing .price span {
          padding-left: 0.5rem;
          text-transform: none;
          font-size: 1.5rem;
          color: #232323; }
          #slider .slide .content .pricing .price span.discount {
            display: block;
            position: relative;
            top: 1rem;
            left: 9rem;
            background: #ed1d25;
            width: 3rem;
            height: 3rem;
            padding: 0.625rem 0.675rem 0.675rem 0.675rem;
            border-radius: 50%;
            text-transform: uppercase;
            font-size: 0.7rem;
            text-align: center;
            color: #ffffff; }
          #slider .slide .content .pricing .price span em {
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            color: #ed1d25; }
            #slider .slide .content .pricing .price span em sup {
              font-weight: 500;
              font-size: 0.6rem; }
      #slider .slide .content .pricing a.button {
        position: absolute;
        right: 0;
        bottom: 0; }

#slider ul.slick-dots {
  position: absolute;
  right: 10px;
  bottom: 5px;
  margin: 0;
  padding: 0;
  text-align: right;
  list-style: none;
  font-size: 0; }
  #slider ul.slick-dots li {
    display: inline-block;
    padding: 0 0 0 0.5rem; }
    #slider ul.slick-dots li button {
      display: block;
      background: none;
      width: 0.75rem;
      height: 0.75rem;
      padding: 0;
      border: 1px solid #232323;
      border-radius: 50%;
      text-indent: -999rem;
      text-align: left; }
    #slider ul.slick-dots li.slick-active button {
      background: #232323; }

main {
  position: relative;
  background: #ffffff; }
  main:before {
    content: '';
    display: block;
    position: absolute;
    top: -1rem;
    width: 100%;
    height: 1rem;
    box-shadow: 0 0.25rem 0.5rem #c2c2c2; }
  main #page-header {
    background: transparent; }
  main #callouts {
    background: #f3f3f3;
    padding: 3rem 1.25rem;
    text-align: center; }
    main #callouts ul {
      padding: 0;
      list-style: none; }
      main #callouts ul li {
        display: inline-block;
        width: 25%;
        text-transform: uppercase;
        text-align: center; }
        main #callouts ul li i {
          display: block;
          background: no-repeat center center;
          background-size: auto 60px;
          width: 105px;
          height: 105px;
          margin: 0 auto 1rem;
          border: 2px solid #60c421;
          border-radius: 50%; }
        main #callouts ul li#nationwide-delivery i {
          background-image: url("/img/icon-nationwide-delivery.png"); }
        main #callouts ul li#speedy-service i {
          background-image: url("/img/icon-speedy-service.png"); }
        main #callouts ul li#wholesale-prices i {
          background-image: url("/img/icon-wholesale-prices.png"); }
  main .features {
    margin-top: 3rem;
    width: 90%; }
    main .features .slick-track .slick-slide {
      height: auto;
      min-height: 285px; }
    main .features .slide {
      position: relative;
      margin: 1rem;
      box-shadow: 0px 0px 27px -6px rgba(35, 35, 35, 0.14); }
      main .features .slide .image {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        padding: 1rem; }
        main .features .slide .image figure {
          height: 100%;
          margin: 0; }
          main .features .slide .image figure img {
            max-width: 100%;
            max-height: 100%; }
      main .features .slide .content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        padding: 1rem;
        text-align: left; }
        main .features .slide .content h3 {
          margin: 0 0 1rem;
          font-size: 1rem;
          font-weight: 400;
          text-align: left; }
        main .features .slide .content p {
          margin: 0;
          font-size: 0.9rem;
          color: #555555;
          line-height: 1.4em; }
          main .features .slide .content p.old-price, main .features .slide .content p.new-price {
            text-transform: uppercase;
            padding: 0.25rem 0;
            font-size: 0.8rem; }
            main .features .slide .content p.old-price span, main .features .slide .content p.new-price span {
              float: right;
              margin-top: -3px;
              clear: right; }
            main .features .slide .content p.old-price em, main .features .slide .content p.new-price em {
              display: inline-block;
              vertical-align: middle;
              font-size: 0.6rem;
              font-style: normal; }
            main .features .slide .content p.old-price strong, main .features .slide .content p.new-price strong {
              display: inline-block;
              vertical-align: middle;
              font-size: 1rem;
              font-weight: 400; }
          main .features .slide .content p.old-price {
            margin-top: 0.5rem; }
          main .features .slide .content p.new-price strong {
            color: #ed1d25; }
        main .features .slide .content a.button {
          position: absolute;
          bottom: 1rem;
          left: 1rem;
          font-size: 0.75rem; }
          main .features .slide .content a.button:hover {
            background: #60c421;
            color: #ffffff;
            border-color: #60c421;
            transition: 0.5s; }
  main .offer-price-holder {
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    font-size: 14px;
    color: #fff;
    line-height: 70px;
    text-align: center;
    background: #e51b25; }
  main .container {
    padding: 1.25rem; }
  main #introduction {
    padding: 2rem 1.25rem; }
  main .panel {
    padding: 2rem 1.25rem;
    text-align: center; }
    main .panel h1 {
      position: relative;
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      font-weight: 500;
      font-size: 112%; }
      main .panel h1:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #232323;
        width: 3.5rem;
        height: 2px;
        margin: 0 auto; }
    main .panel h2 {
      position: relative;
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      font-weight: 500;
      font-size: 112%; }
      main .panel h2:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #232323;
        width: 3.5rem;
        height: 2px;
        margin: 0 auto; }
    main .panel h3 {
      position: relative;
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 112%; }
      main .panel h3:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #232323;
        width: 3.5rem;
        height: 2px;
        margin: 0 auto; }
    main .panel p {
      margin-bottom: 1.5rem;
      line-height: 1.5;
      font-size: 100%;
      color: #555555; }
  main #contact {
    text-align: left; }
    main #contact .contact-text {
      padding-right: 7.5%; }
    main #contact .contact-form label {
      display: none; }
    main #contact .contact-form .recaptcha {
      float: left; }
    main #contact .contact-form .g-recaptcha {
      padding-bottom: 2rem; }
    main #contact .contact-form .buttons {
      margin: 0;
      width: 100%; }
    main #contact h3 {
      padding-bottom: 0;
      text-align: left; }
      main #contact h3:after {
        content: none; }
    main #contact p {
      font-size: 100%; }
    main #contact .buttons button, main #contact .buttons .button {
      padding: 0.75rem 2rem; }
    main #contact #contact_message {
      margin-bottom: 1rem; }
    main #contact .g-recaptcha {
      padding-bottom: 1rem !important; }
  main #stockists ul {
    margin: 0 0 1rem;
    padding: 0; }
    main #stockists ul li {
      display: inline-block;
      margin: 0 3rem;
      vertical-align: top; }
      main #stockists ul li a {
        display: block;
        width: 100%; }
        main #stockists ul li a img {
          display: block;
          width: 100%; }
  main #account {
    padding: 5rem 1rem; }
    main #account #account-nav {
      float: left;
      width: 20%;
      margin: 0 5% 0 0;
      padding: 0;
      vertical-align: top; }
      main #account #account-nav h3 {
        display: inline-block;
        width: auto;
        margin: 0 0 1.5rem;
        padding: 0;
        text-align: left;
        font-size: 1.1rem;
        font-weight: 400; }
      main #account #account-nav ul {
        margin: 0 0 1rem;
        padding: 0;
        border-radius: 2px; }
        main #account #account-nav ul li {
          display: block;
          border-top: 1px solid rgba(194, 194, 194, 0.3);
          list-style: none; }
          main #account #account-nav ul li:first-child {
            border-top: 0 none; }
            main #account #account-nav ul li:first-child a {
              border-top-right-radius: 2px;
              border-top-left-radius: 2px; }
          main #account #account-nav ul li:last-child a {
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 2px; }
          main #account #account-nav ul li.logout {
            float: none;
            width: 100%;
            padding: 2rem 0 0 0;
            border: 0 none;
            font-size: 90%; }
            main #account #account-nav ul li.logout a {
              background-color: #e64747;
              padding: 0.5rem 1rem;
              text-align: center;
              border-radius: 4px;
              color: #ffffff; }
          main #account #account-nav ul li a {
            display: block;
            padding: 0.75rem 0.5rem;
            text-decoration: none;
            color: #555555; }
            main #account #account-nav ul li a:hover, main #account #account-nav ul li a.active {
              color: #88e250; }
    main #account #account-content {
      float: left;
      width: 75%;
      margin: 0;
      padding: 0;
      vertical-align: top; }
      main #account #account-content h1 {
        margin-top: 0; }
      main #account #account-content .empty {
        text-align: center; }
      main #account #account-content #edit-account-details fieldset {
        margin-top: 1rem; }
    main #account #edit-account-details .col1of1,
    main #account #edit-account-details .col1of2 {
      padding: 0 0.5rem; }
    main #account #edit-account-details .buttons {
      text-align: right; }
      main #account #edit-account-details .buttons button {
        margin-right: 0.5rem; }
    main #account #update-password .buttons {
      text-align: right; }

#products #sidebar {
  vertical-align: top; }

.product-list {
  width: 75%; }

#product-list {
  display: inline-block;
  margin: 0;
  vertical-align: top;
  text-align: center;
  width: 100%; }
  #product-list .product-container {
    display: inline-block;
    position: relative;
    background: #ffffff;
    width: 30%;
    margin: 8px 6px;
    padding: 1rem;
    vertical-align: top;
    text-align: center;
    list-style: none;
    box-shadow: 0px 0px 27px -6px rgba(35, 35, 35, 0.14); }
    #product-list .product-container a {
      display: block;
      width: 100%;
      padding: 1rem;
      text-decoration: none;
      color: #6f6f6f; }
      #product-list .product-container a span {
        padding-left: 0.5rem;
        text-transform: none;
        font-size: 1.5rem;
        color: #232323; }
        #product-list .product-container a span.discount {
          display: block;
          position: absolute;
          top: 1rem;
          right: 1rem;
          background: #ed1d25;
          width: 3rem;
          height: 3rem;
          padding: 0.625rem 0.675rem 0.675rem 0.675rem;
          border-radius: 50%;
          text-transform: uppercase;
          font-size: 0.7rem;
          text-align: center;
          color: #ffffff; }
      #product-list .product-container a img {
        display: block;
        width: 100%; }
      #product-list .product-container a .description {
        margin-top: 1rem;
        font-size: 0.9rem; }
        #product-list .product-container a .description p {
          margin: 0;
          font-weight: 400; }
          #product-list .product-container a .description p.price {
            margin-top: 1rem;
            color: #60c421; }
            #product-list .product-container a .description p.price.discount {
              color: #ed1d25; }
            #product-list .product-container a .description p.price small {
              display: inline-block;
              text-transform: uppercase;
              vertical-align: middle;
              font-size: 0.6rem;
              color: #6f6f6f; }

#product .product-feature {
  width: 75%; }

#product #product-feature {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 10px; }
  #product #product-feature .text-head {
    padding: 1.25rem 0 0 2.75rem; }
    #product #product-feature .text-head .breadcrumbs {
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      list-style: none;
      font-size: 0.85rem; }
      #product #product-feature .text-head .breadcrumbs li {
        display: inline;
        color: #c2c2c2; }
        #product #product-feature .text-head .breadcrumbs li.current {
          color: #232323; }
        #product #product-feature .text-head .breadcrumbs li a {
          text-decoration: none;
          color: #c2c2c2; }
    #product #product-feature .text-head .product-code {
      margin: 0.75rem 0 1.5rem;
      font-size: 0.8rem;
      color: rgba(85, 85, 85, 0.75); }
    #product #product-feature .text-head h1 {
      margin-bottom: 0; }
  #product #product-feature #product-slider {
    display: inline-block;
    width: 79%;
    vertical-align: middle; }
    #product #product-feature #product-slider .slide {
      margin: 0; }
      #product #product-feature #product-slider .slide img {
        width: auto;
        max-width: 100%;
        max-height: 430px;
        margin: 0 auto; }
    #product #product-feature #product-slider .slick-arrow {
      width: 2rem;
      height: 2rem;
      margin: -1rem 0 0 0;
      z-index: 2; }
      #product #product-feature #product-slider .slick-arrow.slick-prev {
        left: 2rem;
        background-image: url("/img/arrow-left.png"); }
      #product #product-feature #product-slider .slick-arrow.slick-next {
        right: 2rem;
        background-image: url("/img/arrow-right.png"); }
  #product #product-feature #product-slider-nav {
    display: inline-block;
    width: 20%;
    vertical-align: middle; }
    #product #product-feature #product-slider-nav .slick-prev {
      top: 0;
      left: 50%;
      margin: 0;
      transform: rotate(90deg) translate(-100%, 50%); }
    #product #product-feature #product-slider-nav .slick-next {
      top: auto;
      bottom: 0;
      left: 50%;
      margin: 0;
      transform: rotate(90deg) translate(100%, 50%); }
    #product #product-feature #product-slider-nav .slick-slide {
      width: 7.5rem;
      margin: 0;
      padding: 0.25rem; }
      #product #product-feature #product-slider-nav .slick-slide:focus {
        outline: none; }
      #product #product-feature #product-slider-nav .slick-slide.slick-current {
        opacity: 0.5; }
      #product #product-feature #product-slider-nav .slick-slide img {
        display: block;
        width: 100%;
        margin: 0 auto; }

#product #product-options {
  position: relative;
  background-color: #f3f3f3;
  padding: 1rem; }
  #product #product-options fieldset {
    display: inline-block;
    padding: 0.5rem;
    vertical-align: middle;
    text-align: left; }
    #product #product-options fieldset label {
      margin: 0 0 0.25rem;
      padding: 0;
      text-transform: uppercase;
      font-weight: 300; }
    #product #product-options fieldset input {
      margin: 0; }
    #product #product-options fieldset select {
      width: 100%;
      height: auto;
      margin: 0; }
    #product #product-options fieldset.buttons button, #product #product-options fieldset.buttons .button {
      margin: 0; }
    #product #product-options fieldset .addtocart {
      margin-top: 5px !important; }
  #product #product-options #cart-success-message {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #7ED648;
    width: 100%;
    padding: 2.5rem 2rem;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: 1s;
    transition-timing-function: ease-in;
    color: #ffffff;
    z-index: 2; }
    #product #product-options #cart-success-message.hidden {
      display: none; }
    #product #product-options #cart-success-message.hiding {
      opacity: 0;
      transition: 1s;
      transition-timing-function: ease-in; }
    #product #product-options #cart-success-message p {
      margin: 0;
      color: #ffffff; }

#product .product-details {
  background: none;
  padding-top: 1rem;
  text-align: left; }
  #product .product-details .row {
    max-width: 800px;
    padding: 2rem 0;
    border-bottom: 1px solid #c2c2c2; }
    #product .product-details .row:last-child {
      border: none; }
    #product .product-details .row table {
      width: 100%; }
    #product .product-details .row dl dt {
      float: left;
      display: block;
      width: 185px;
      padding: 0.25rem 0;
      clear: left; }
    #product .product-details .row dl dd {
      float: left;
      display: block;
      padding: 0.25rem 0; }
  #product .product-details .product-description {
    padding-bottom: 1rem;
    font-size: 0.9rem; }
  #product .product-details .specs {
    overflow: hidden;
    font-size: 0.8rem; }
    #product .product-details .specs tr:nth-of-type(even) {
      background-color: rgba(243, 243, 243, 0.5); }
    #product .product-details .specs th {
      background: none; }
    #product .product-details .specs td {
      border: 0 none; }
  #product .product-details table th, #product .product-details table td {
    padding: 0.65rem 1rem; }
  #product .product-details table th {
    background: #f3f3f3; }
  #product .product-details table tbody td {
    border-right: 1px solid #f3f3f3;
    text-transform: uppercase; }
    #product .product-details table tbody td:last-child {
      border: none; }
    #product .product-details table tbody td .onsale {
      display: block;
      font-size: 0.65rem;
      clear: both; }
      #product .product-details table tbody td .onsale.discount {
        color: #ed1d25; }
    #product .product-details table tbody td .newprice {
      color: #ed1d25; }
      #product .product-details table tbody td .newprice small {
        font-size: 0.65rem; }

#product #related-products {
  background: #f3f3f3; }
  #product #related-products h3 {
    margin: 0 0 2.5rem;
    padding-bottom: 1rem;
    font-size: 1rem; }
  #product #related-products ul {
    margin: 0;
    padding: 0;
    text-align: center; }
    #product #related-products ul li {
      display: inline-block;
      background: #ffffff;
      width: 23%;
      margin: 0.5rem;
      padding: 1rem;
      vertical-align: top;
      list-style: none;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12); }
      #product #related-products ul li a {
        display: block;
        width: 100%;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 0.85rem;
        color: #555555; }
        #product #related-products ul li a img {
          display: block;
          width: 100%;
          margin-bottom: 1rem; }

#shopping-cart #cart {
  background: none;
  padding: 4.5rem 1.25rem; }
  #shopping-cart #cart h3 {
    position: relative;
    margin-bottom: 2rem;
    padding-bottom: 1.1rem;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 1.5rem; }
    #shopping-cart #cart h3:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #232323;
      width: 3.5rem;
      height: 2px;
      margin: 0 auto; }
  #shopping-cart #cart .buttons {
    margin-top: 2rem;
    text-align: right; }
    #shopping-cart #cart .buttons button,
    #shopping-cart #cart .buttons .button {
      display: inline-block; }
  #shopping-cart #cart.removing {
    animation: unpop .25s forwards; }

#cart-table {
  width: 100%; }
  #cart-table thead th {
    border-bottom: 1px solid #f3f3f3;
    text-transform: uppercase;
    font-weight: 300; }
  #cart-table tbody tr:last-child td {
    border-bottom: 1px solid #f3f3f3; }
  #cart-table tbody tr:last-child.empty td {
    border-bottom: 0 none; }
  #cart-table tfoot {
    text-transform: uppercase;
    font-size: 0.9rem; }
    #cart-table tfoot tr {
      position: relative; }
      #cart-table tfoot tr.coupon {
        color: #e51b25; }
    #cart-table tfoot .shipping td {
      padding-top: 1.5rem; }
    #cart-table tfoot .gst td {
      padding-bottom: 1.5rem; }
    #cart-table tfoot .total td {
      padding-top: 1.5rem; }
    #cart-table tfoot td {
      padding: 0.5rem 1rem; }
    #cart-table tfoot td.label {
      text-align: right; }
    #cart-table tfoot td.amount {
      text-align: right; }
    #cart-table tfoot tr.total td.label, #cart-table tfoot tr.total td.amount {
      border-top: 1px solid #f3f3f3;
      font-weight: 600; }
  #cart-table td, #cart-table th {
    padding: 1rem;
    text-align: center; }
    #cart-table td.delete, #cart-table th.delete {
      width: 2rem;
      padding: 0.65rem;
      text-align: left; }
      #cart-table td.delete a.button, #cart-table th.delete a.button {
        background: url("/img/btn-delete.png") no-repeat center center;
        background-size: 36%;
        width: 30px;
        height: 30px;
        padding: 0;
        line-height: 30px;
        border-radius: 50%;
        border-color: #c2c2c2;
        text-indent: -999rem; }
        #cart-table td.delete a.button:hover, #cart-table th.delete a.button:hover {
          border-color: #232323; }
        #cart-table td.delete a.button.loading, #cart-table th.delete a.button.loading {
          background-image: url("/img/spinner-loading.gif");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: auto 80%;
          color: transparent; }
    #cart-table td.product-image, #cart-table th.product-image {
      width: 115px;
      text-align: left; }
      #cart-table td.product-image img, #cart-table th.product-image img {
        display: block;
        max-width: 115px;
        max-height: 115px;
        margin: 0 auto; }
    #cart-table td.product, #cart-table th.product {
      text-align: left; }
      #cart-table td.product .product-code, #cart-table th.product .product-code {
        font-size: 0.7rem;
        color: #c2c2c2; }
      #cart-table td.product .product-name, #cart-table th.product .product-name {
        margin: 1rem 0 0;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1rem; }
      #cart-table td.product .product-option, #cart-table th.product .product-option {
        font-size: 0.85rem; }
    #cart-table td.qty, #cart-table th.qty {
      width: 130px; }
      #cart-table td.qty input, #cart-table th.qty input {
        width: 6rem;
        margin-bottom: 0; }
      #cart-table td.qty .updowner, #cart-table th.qty .updowner {
        margin-left: 0; }
    #cart-table td.total, #cart-table td.price, #cart-table th.total, #cart-table th.price {
      text-align: right; }

#coupon-form {
  float: left;
  margin-top: -42px; }
  #coupon-form #coupon-code {
    width: 200px;
    height: 41px; }
  #coupon-form button {
    display: initial;
    padding: 0.75rem 1rem; }

footer {
  padding: 3.5rem 1.25rem 1.5rem 1.25rem;
  font-size: 0.765rem;
  text-align: center;
  color: #c2c2c2;
  line-height: 2em;
  background: #363636;
  z-index: 10; }
  footer ul {
    margin: 0 0 2rem;
    padding: 0;
    list-style: none; }
    footer ul li {
      display: inline-block;
      width: auto;
      margin: 0 1rem;
      clear: none; }
      footer ul li a {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 0.85rem;
        font-weight: 200;
        color: #ffffff; }
  footer span {
    display: inline-block;
    margin: 0 0.5rem; }
    footer span.credit a {
      color: #c2c2c2; }

.pagination {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 75%;
  float: right;
  padding: 20px; }
  .pagination li {
    display: inline-block;
    width: auto;
    margin: 0 0.25rem;
    padding: 0;
    text-align: center;
    list-style: none;
    font-size: 80%; }
    .pagination li.disabled span {
      background: white;
      color: #c2c2c2; }
    .pagination li.active span {
      background: #d2f4bd;
      border-color: #97e566;
      color: #60c421; }
    .pagination li span {
      display: block;
      background-clip: padding-box;
      width: 2rem;
      height: 2rem;
      padding: 0.5rem 0 0.5rem 0.1rem;
      border: 1px solid transparent;
      border-radius: 100%;
      line-height: 1.1; }
    .pagination li a {
      display: block;
      background-clip: padding-box;
      width: 2rem;
      height: 2rem;
      padding: 0.5rem 0 0.5rem 0.1rem;
      border: 1px solid rgba(220, 220, 220, 0.66);
      text-decoration: none;
      border-radius: 100%;
      line-height: 1.1;
      color: #555555; }
      .pagination li a:hover {
        background-color: whitesmoke;
        border-color: gainsboro;
        color: #555555; }

body#home .home-container {
  padding-top: 0; }

body#home .sidebar {
  padding-top: 1.25rem;
  width: 20%; }

body#home #slider {
  display: inline-block;
  width: 100%; }

body#home #quick-search {
  margin: 1rem auto 4rem;
  border-bottom: 2px solid #c2c2c2;
  text-align: center; }
  body#home #quick-search ul {
    display: inline-block;
    position: relative;
    top: 1.2rem;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    list-style: none; }
    body#home #quick-search ul li {
      display: inline-block;
      background: #ffffff;
      width: 25%;
      padding: 0 0.5rem; }
      body#home #quick-search ul li a {
        display: block;
        background: #555555;
        border-color: #555555; }
        body#home #quick-search ul li a:hover {
          border-color: #60c421;
          background: #60c421;
          transition: 0.5s; }

body#home .panel:nth-child(odd) {
  background: #f3f3f3; }

body#home h1 {
  border-bottom: 0 none; }

#checkout-detail-ordersummary {
  text-align: left;
  clear: left; }
  #checkout-detail-ordersummary p {
    font-size: 1rem; }

#shipping-address .note {
  padding: 0.25rem 0 0.2rem 0.6rem;
  margin: 0;
  font-size: 0.8em;
  text-align: left; }

#shipping-address h4 {
  margin: 0.75rem 0 0 0; }

#checkout-detail-form {
  float: left;
  text-align: left; }
  #checkout-detail-form #credit-card-type {
    margin-bottom: 1rem;
    padding: 0.5rem 0 0;
    clear: left; }
    #checkout-detail-form #credit-card-type label:not(:first-of-type) {
      margin-left: 1rem; }
    #checkout-detail-form #credit-card-type input {
      margin: 0.1rem 0.5rem; }
    #checkout-detail-form #credit-card-type .secure-logos {
      float: right;
      margin-top: -5px; }
      #checkout-detail-form #credit-card-type .secure-logos img {
        width: 80px;
        margin: 0 15px; }
  #checkout-detail-form #exp-month {
    display: inline-block;
    width: 45%;
    margin-right: 1rem;
    clear: none; }
  #checkout-detail-form #exp-year {
    display: inline-block;
    width: 45%;
    clear: none; }

#checkout-register {
  text-align: center; }
  #checkout-register h1 {
    margin-top: 0; }
  #checkout-register .col1of1 {
    padding: 0; }
  #checkout-register .member {
    display: inline-block;
    border-right: 1px solid #ccc;
    vertical-align: top;
    text-align: left;
    margin: 50px 0;
    padding: 50px; }
    #checkout-register .member label {
      width: 100%; }
      #checkout-register .member label a {
        float: right;
        margin-top: 2px;
        text-decoration: none;
        font-weight: 300;
        font-size: 80%; }
    #checkout-register .member button {
      display: inline-block;
      clear: left; }
    #checkout-register .member .nav {
      float: right;
      margin: 0.5rem 1rem;
      font-size: 80%; }
  #checkout-register .guest {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    margin: 50px 0;
    padding: 50px; }

#shipping .buttons button {
  display: inline-block;
  float: right; }

#order-received {
  margin-bottom: 3rem;
  padding: 3rem 0;
  border-bottom: 2px solid #555555;
  text-align: center; }

#checkout-complete #order-summary {
  max-width: 60rem;
  margin: 0 auto 5rem; }

#order-history tbody tr:hover {
  background: #f3f3f3; }

#order-history tr th:first-child,
#order-history tr td:first-child {
  padding-left: 0.5rem; }

#order-history th {
  padding: 0.75rem 0.5rem;
  text-align: left; }

#order-history td {
  padding: 0.75rem 0.5rem;
  border-top: 1px solid #f3f3f3;
  font-size: 0.9rem; }

#order-history .view {
  padding: 0.5rem;
  text-align: center; }
  #order-history .view a {
    display: block;
    padding: 0.5rem 0.5rem;
    text-decoration: none; }
    #order-history .view a:hover {
      background: #60c421;
      color: #ffffff; }

#order-summary-products {
  margin: 1rem 0;
  padding-bottom: 3rem;
  border-bottom: 1px solid #c2c2c2; }

#order-summary-status {
  float: left;
  display: block;
  width: 33.33%;
  font-size: 0.9rem;
  line-height: 1.6; }
  #order-summary-status dt {
    float: left;
    display: block;
    width: 7rem;
    font-weight: 500;
    clear: left; }
  #order-summary-status dd {
    float: left;
    display: block;
    margin-left: 1rem; }

#order-summary-shipping {
  float: left;
  display: block;
  width: 33.33%;
  font-size: 0.9rem;
  line-height: 1.6; }

#order-summary-billing {
  float: left;
  display: block;
  width: 33.33%;
  font-size: 0.9rem;
  line-height: 1.6; }

.updowner {
  display: inline-block;
  margin-left: 2rem;
  border-radius: 0.35rem;
  border: 1px solid #c2c2c2;
  vertical-align: middle;
  text-align: center;
  overflow: hidden; }
  .updowner input {
    display: none !important; }
  .updowner a.updowner-switch {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    vertical-align: middle;
    text-decoration: none;
    line-height: 1;
    color: #555555; }
    .updowner a.updowner-switch.updowner-down {
      border-right: 1px solid #c2c2c2; }
    .updowner a.updowner-switch.updowner-up {
      border-left: 1px solid #c2c2c2; }
    .updowner a.updowner-switch.inactive {
      cursor: default;
      color: #f3f3f3; }
      .updowner a.updowner-switch.inactive:hover {
        background: #ffffff; }
    .updowner a.updowner-switch:hover {
      background: white;
      text-decoration: none; }
  .updowner .updown_product_quantity {
    word-spacing: -3px; }
  .updowner span.updowner-output {
    display: inline-block;
    width: 2.25rem;
    height: 2rem;
    padding: 0.5rem;
    vertical-align: middle;
    cursor: default;
    line-height: 1; }

#about-us p {
  line-height: 1.5em;
  font-size: 0.9em; }

#about-us h2 {
  padding-top: 8px; }

.notice-container {
  background: #f3f3f3; }
  .notice-container .row {
    padding: 0 1rem; }
  .notice-container .notice {
    display: inline-block; }
    .notice-container .notice p {
      display: inline-block;
      font-size: 0.9em;
      max-width: 530px; }
      .notice-container .notice p b {
        font-weight: 400;
        color: #60c421; }
    .notice-container .notice img {
      display: inline-block;
      margin-right: 1em;
      width: 40px;
      height: 40px;
      vertical-align: text-bottom; }

#cart .notice-container.bottom, #checkout .notice-container.bottom {
  margin-top: 1em; }

@media only screen and (max-width: 1177px) {
  #sidebar nav.categories ul li {
    font-size: 0.84rem; } }

@media only screen and (max-width: 1140px) {
  .slider .pricing {
    position: initial !important; }
    .slider .pricing a {
      position: initial !important;
      margin-top: 10px; }
  .slider .content {
    padding-left: 10px !important; }
  main .features .slide .content p {
    font-size: 0.8rem !important; } }

@media only screen and (max-width: 1024px) {
  .sidebar, .slider {
    width: 100% !important; }
  .sidebar #sidebar {
    width: 100%; }
  .sidebar .categories ul {
    -moz-column-count: 2;
    -moz-column-gap: 2.5em;
    -webkit-column-count: 2;
    -webkit-column-gap: 2.5em;
    -moz-column-count: 2;
    column-count: 2;
    -moz-column-gap: 2.5em;
    column-gap: 2.5em; } }

@media only screen and (max-width: 940px) {
  header #free-shipping {
    right: 35%; } }

@media only screen and (max-width: 880px) {
  #quick-search {
    border-bottom: none !important; }
    #quick-search ul li {
      width: 33.3% !important; }
      #quick-search ul li a {
        font-size: 0.8em; }
  #callouts ul li {
    width: 33.3% !important; } }

@media only screen and (max-width: 850px) {
  header .logo {
    width: 200px; } }

@media only screen and (max-width: 840px) {
  #related-products ul li {
    width: 47% !important; }
  #slider .slide .image {
    height: 280px; }
    #slider .slide .image img {
      width: 100%;
      height: auto; }
  #slider .slide .content .off {
    font-size: 2em; }
  #slider .slide .content .name {
    margin: 0;
    color: #555555;
    font-weight: 300; } }

@media only screen and (max-width: 768px) {
  header {
    z-index: 4;
    position: fixed;
    width: 100%;
    box-shadow: 0 0 0.65rem #c2c2c2; }
    header .row {
      padding: 0; }
    header .logo {
      height: 60px;
      width: 165px;
      margin-left: 20px; }
    header .nav-collapse {
      display: block; }
    header #account, header #logout {
      display: none; }
  #free-shipping {
    display: none !important; }
  .tools-nav .search, .tools-nav #login {
    display: none; }
  .tools-nav #cart-icon {
    position: absolute;
    top: 17px;
    right: 75px; }
  .site-nav, .navigation-full-size {
    display: none !important; }
  .site-nav-collapse {
    display: block;
    height: 0; }
    .site-nav-collapse ul li {
      display: block;
      text-align: center; }
      .site-nav-collapse ul li a {
        margin: 0 auto; }
    .site-nav-collapse .tools-nav-collapse {
      float: none;
      padding-top: 0;
      width: 100%; }
      .site-nav-collapse .tools-nav-collapse ul li {
        margin: 0 auto !important;
        max-width: none !important; }
        .site-nav-collapse .tools-nav-collapse ul li.search a {
          background-size: 25px !important; }
        .site-nav-collapse .tools-nav-collapse ul li.search.active {
          right: 50%;
          transform: translateX(50%); }
          .site-nav-collapse .tools-nav-collapse ul li.search.active input {
            right: 50%;
            transform: translateX(50%); }
          .site-nav-collapse .tools-nav-collapse ul li.search.active button {
            display: none; }
        .site-nav-collapse .tools-nav-collapse ul li#cart-icon a {
          background-size: 25px !important; }
  #products .product-list {
    width: 100%; }
  #products .pagination {
    width: 100%; }
  #product .product-feature {
    width: 100%;
    padding-top: 20px; }
    #product .product-feature #product-feature {
      padding-left: 0 !important; }
  #checkout-register .member, #checkout-register .guest {
    padding: 0;
    margin: 20px 0;
    border-right: 0; }
  #reset-password form {
    margin: 0 auto;
    padding: 30px; }
  #register form {
    margin: 0 auto;
    padding: 30px; }
  #login form {
    margin: 0 auto;
    padding: 30px; }
  main #account {
    padding: 2rem 1rem !important; }
    main #account #account-nav {
      float: none !important;
      width: 70% !important;
      margin: 0 auto !important; }
    main #account #account-content {
      width: 100% !important;
      padding: 20px !important; }
      main #account #account-content #order-history {
        overflow: auto; }
  .notice-container {
    margin-top: 60px; }
    .notice-container .notice {
      max-width: 470px; }
      .notice-container .notice img {
        display: none; } }

@media only screen and (max-width: 700px) {
  .slider .content .description {
    display: none; }
  #shopping-cart #cart-table, #view-order #cart-table {
    display: block; }
    #shopping-cart #cart-table thead, #view-order #cart-table thead {
      display: none; }
    #shopping-cart #cart-table tbody, #view-order #cart-table tbody {
      display: table;
      margin: 0 auto;
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 20px; }
      #shopping-cart #cart-table tbody td, #view-order #cart-table tbody td {
        display: block;
        width: 100%; }
    #shopping-cart #cart-table tfoot, #view-order #cart-table tfoot {
      display: table;
      width: 100%; }
  #shopping-cart #cart #cart-table, #shopping-cart #account #cart-table, #view-order #cart #cart-table, #view-order #account #cart-table {
    border: none; }
    #shopping-cart #cart #cart-table tbody tr, #shopping-cart #account #cart-table tbody tr, #view-order #cart #cart-table tbody tr, #view-order #account #cart-table tbody tr {
      background: #ffffff;
      box-shadow: 0px 0px 27px -6px rgba(35, 35, 35, 0.14); }
    #shopping-cart #cart #cart-table tbody .qty, #shopping-cart #account #cart-table tbody .qty, #view-order #cart #cart-table tbody .qty, #view-order #account #cart-table tbody .qty {
      float: right; }
      #shopping-cart #cart #cart-table tbody .qty input, #shopping-cart #account #cart-table tbody .qty input, #view-order #cart #cart-table tbody .qty input, #view-order #account #cart-table tbody .qty input {
        float: right; }
    #shopping-cart #cart #cart-table tbody .total, #shopping-cart #account #cart-table tbody .total, #view-order #cart #cart-table tbody .total, #view-order #account #cart-table tbody .total {
      float: right; }
    #shopping-cart #cart #cart-table tbody .product-image img, #shopping-cart #account #cart-table tbody .product-image img, #view-order #cart #cart-table tbody .product-image img, #view-order #account #cart-table tbody .product-image img {
      max-width: none;
      max-height: none;
      width: 100%; }
  #shopping-cart #cart table, #shopping-cart #cart tr, #shopping-cart #cart td, #shopping-cart #account table, #shopping-cart #account tr, #shopping-cart #account td, #view-order #cart table, #view-order #cart tr, #view-order #cart td, #view-order #account table, #view-order #account tr, #view-order #account td {
    border-bottom: none !important; }
  #shopping-cart #cart #account-content, #shopping-cart #account #account-content, #view-order #cart #account-content, #view-order #account #account-content {
    padding: 10px !important; }
  #coupon-form {
    float: right;
    margin-top: -100px; }
  #cart .buttons {
    float: right;
    width: 100%;
    margin-top: 5rem !important; } }

@media only screen and (max-width: 650px) {
  #home #quick-search {
    border-bottom: none !important; }
    #home #quick-search ul li {
      display: block;
      width: 100% !important;
      margin-top: 5px; }
  main .features {
    width: 80% !important;
    margin-top: 2rem; }
    main .features .content {
      font-size: 0.9em !important;
      position: initial !important;
      padding: 0 1rem 4rem 1rem !important; }
    main .features .image {
      position: initial !important;
      width: 100% !important; }
      main .features .image img {
        margin: 0 auto; }
  main #introduction {
    padding: 2rem 1.25rem; }
  main .panel {
    padding: 2rem 1.25rem; }
  #product #product-options fieldset {
    display: block; }
  #cart-success-message {
    padding: 7rem 2rem !important; }
  #slider .slide .image {
    height: auto;
    padding: 0; }
    #slider .slide .image img {
      margin: 0 auto;
      position: initial;
      transform: initial; }
  #slider .slide .content {
    margin: 12px auto 0 auto;
    position: initial;
    transform: initial; }
    #slider .slide .content .off {
      font-size: 2em; }
    #slider .slide .content .name {
      margin: 0;
      color: #555555;
      font-weight: 300; }
    #slider .slide .content .button {
      margin-top: 12px; }
  #slider .slick-dots {
    display: none !important; } }

@media only screen and (max-width: 610px) {
  #callouts ul li {
    width: 100% !important;
    margin: 20px 0; }
  .product-details table {
    font-size: 80%; }
    .product-details table th, .product-details table td {
      padding: 0.5rem 0.8rem !important; }
  #product-list .product-container {
    width: 47%; } }

@media only screen and (max-width: 572px) {
  #related-products ul li {
    width: 100% !important;
    margin: 5px 0 !important; } }

@media only screen and (max-width: 520px) {
  #slider .slide {
    display: block !important; }
    #slider .slide .image, #slider .slide .content {
      width: 100%; }
  footer ul li {
    display: block; }
  #product #product-slider-nav {
    display: none !important; }
  #product #product-slider {
    width: 100% !important; }
    #product #product-slider .slick-arrow.slick-prev {
      left: 0.5rem !important; }
    #product #product-slider .slick-arrow.slick-next {
      right: 0.5rem !important; }
  #product .text-head {
    padding: 1.25rem 0 0 0 !important; }
  #product-list .product-container {
    width: 100%;
    margin: 8px 0; } }

@media only screen and (max-width: 450px) {
  .sidebar {
    text-align: center; }
    .sidebar .categories ul {
      -moz-column-count: 1;
      column-count: 1; }
  #coupon-form #coupon-code {
    width: 124px; } }

span#view-responsive {
  display: none; }

span#view-responsive:hover, span#view-full:hover {
  color: #60c421; }

@media screen and (min-width: 800px) {
  span#view-full {
    display: none; }
  span#view-responsive {
    display: inline; } }

/*# sourceMappingURL=adhesivetapes.css.map */

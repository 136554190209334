@import "_colours.scss";
@import "_form.scss";
@import "_messages.scss";
@import "_breakpoints.scss";


.clearfix:before,
.clearfix:after {
    content: '';
    display: table;
    clear: both;
}

body {
    min-height: 100%;
    font-family: 'Montserrat';
    height: auto; // Infinite Scroll bug
    font-weight: 300;
}


// Links

a {
    color: $link;

    &:hover {
        color: lighten($link, 10%);
    }

    &:active {
        color: lighten($link, 5%);
    }

    &.logo.adhesivetapes {
        display: block;
        background: url('/img/logo-adhesive-tapes.png') no-repeat center center;
        background-size: contain;
        text-indent: -999rem;
    }
}


// Buttons and Button-style links

button,
.button {
    display: block;
    background: $primary;
    height: auto;
    padding: 0.75rem 1rem;
    letter-spacing: 0;
    border: 2px solid $primary;
    border-radius: 0;
    line-height: 1;
    color: $white;

    &:hover, &:focus {
        background: darken($primary, 10%);
        border-color: darken($primary, 10%);
        color: $white;
    }

    &.secondary {
        background: $white;
        color: $primary;

        &:hover, &:focus {
            color: darken($primary, 10%);
        }
    }

    &.tertiary {
        background: $white;
        padding: 0.8125rem 1rem;
        border: 1px solid $black;
        color: $black;

        &:hover, &:focus {
            border-color: lighten($black, 30%);
            color: lighten($black, 30%);
        }
    }

    &.loading {
        background-image: url('/img/spinner-loading.gif');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 80%;
        color: rgba(0, 0, 0, 0);
    }

    &.disabled {
        background-color: $grey;
        border-color: $grey;
        background-image: url('/img/spinner-loading-disabled.gif');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 80%;
        color: rgba(0, 0, 0, 0);
    }

    &.slick-arrow {
        position: absolute;
        top: 50%;
        background: transparent no-repeat center center;
        background-size: contain;
        width: 1rem;
        height: 1rem;
        margin: -0.5rem 0 0 0;
        padding: 0;
        border: none;
        text-indent: -999rem;

        &.slick-prev {
            background-image: url('/img/arrow-left.png');
            left: -2rem;
        }

        &.slick-next {
            background-image: url('/img/arrow-right.png');
            right: -2rem;
        }
    }

    &.loadmore {
        width: 10rem;
        margin: 2rem auto 0;
    }
}


// Text styles

h1 {
    display: inline-block;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid;
    font-size: 1.7rem;
    font-weight: 400;
}

h2 {
    font-weight: 400;
}

h3 {
    margin: 1rem 0;
    text-align: center;
    font-weight: 100;
    font-size: 200%;
}

h4 {
    margin: 0.75rem 0 1.65rem;
    text-transform: uppercase;
    font-size: 0.865rem;
}

// Header

header {
    position: relative;
    background: $white;
    //z-index: 2;

    .row {
        padding: 0 1rem;
    }

    .logo {
        float: left;
        width: 300px;
        height: 130px;
    }

    #free-shipping {
        display: block;
        position: absolute;
        top: 1.5rem; right: 38.5%;
        background: $charcoal;
        width: 12.5rem;
        margin: 0;
        padding: 0.5rem;
        box-shadow: 0 0 0.65rem $grey;
        transform: rotate(-7.5deg);
        border: 2px solid $primary;
        text-align: center;
        font-size: 1.2rem;
        overflow: hidden;
        color: $white;

        h3 {
            margin: 0 auto;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 1.2rem;
        }

        p {
            margin: 0 auto;
            font-size: 0.75rem;
            color: $primary;

            small {
                text-transform: uppercase;
                font-size: 0.5rem;
                color: $white;
            }
        }
    }
}


// Nav

nav {
    ul {
        display: block;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            display: inline-block;
            width: auto;
            list-style: none;
            clear: none;

            a {
                display: block;
                text-transform: uppercase;
                text-decoration: none;
            }
        }
        .contact-us-nav {
            background: $green;
        }
    }

    &.tools-nav, &.tools-nav-collapse {
        float: right;
        padding-top: 3rem;
        font-size: 0.8rem;

        ul {
            li {
                margin: 0 0 0 1.5rem;
                line-height: 28px;

                a {
                    font-weight: 300;
                    font-size: 110%;
                    color: $black;
                }

                &.search,
                &#cart-icon,
                &#account {
                    margin: 0 0 0 0.5rem;

                    a {
                        display: block;
                        background: no-repeat center center;
                        background-size: contain;
                        width:  28px;
                        height: 28px;
                        text-indent: -999rem;
                        text-align: left;
                    }
                }

                &.search {
                    position: relative;
                    max-width: 10rem;

                    &.active {
                        .search-term {
                            width: 15rem;
                            padding-right: 0.575rem;
                            padding-left: 0.575rem;
                            opacity: 1;
                        }

                        button {
                            display: block;
                        }
                    }

                    a {
                        background-image: url('/img/icon-search-black.png');
                    }

                    .search-term {
                        display: inline-block;
                        position: absolute;
                        top: 0; right: 0;
                        width: 0;
                        margin: -3px 0 0 0;
                        padding: 0.575rem 0;
                        opacity: 0;
                        transition: all 0.5s ease;

                        &:focus {
                            border-color: $black;
                        }
                    }

                    button {
                        display: none;
                        position: absolute;
                        top: 0; right: 3px;
                        background: $white url('/img/icon-search-black.png') no-repeat center center;
                        background-size: 22px;
                        width: 28px;
                        height: 28px;
                        margin: 0;
                        padding: 3px;
                        border: 0 none;
                        text-indent: -999rem;
                    }
                }

                &#cart-icon {
                    a {
                        position: relative;
                        background-image: url('/img/icon-cart-black.png');

                        #cart-count {
                            display: block;
                            position: absolute;
                            right: -1px; bottom: -1px;
                            background: $highlight;
                            height: 15px;
                            padding: 0 3px;
                            text-align: center;
                            border-radius: 3px;
                            font-size: 0.5rem;
                            line-height: 15px;
                            text-indent: 0;
                            color: $white;
                        }
                    }
                }

                &#account {
                    a {
                        background-image: url('/img/icon-account-black.svg');
                        background-size: 24px;
                    }
                }
            }
        }
    }

    &.site-nav {
        background: $primary;
        text-align: right;

        ul {
            li {
                padding: 18px 20px;

                &.home {
                    float: left;
                    margin: 0;
                }

                a {
                    color: $white;

                    &:hover {
                        color: $charcoal;
                    }
                }
            }
        }
    }
}

// Responsive nav

.site-nav-collapse {
    display: none;
    height: 60px;
}
.sidenav {
    display: none;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    padding-top: 80px;
    background-color: $primary;
    overflow-x: hidden;
    transition: 0.5s;
    a {
        padding: 20px;
        text-decoration: none;
        font-size: 1.1em;
        color: $black;
        display: block;
        transition: 0.3s;
        text-transform: uppercase;
    }
    .main-nav-collapse {
        a {
            &:hover {
                background: $white;
            }
        }
    }
}
.nav-open {
    .sidenav {
        width: 100%;
        display: initial;
    }
}
.nav-collapse {
    display: none;
    position: relative;
    width: 35px;
    float: right;
    transition: all 0.5s ease;
}
#nav-icon {
    width: 35px;
    height: 26px;
    position: absolute;
    top: 20px;
    right: 20px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}
#nav-icon span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: $primary;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
#nav-icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}
#nav-icon span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}
#nav-icon span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}
.nav-open #nav-icon span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
}
.nav-open #nav-icon span:nth-child(2) {
    width: 0%;
    opacity: 0;
}
.nav-open #nav-icon span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 22px;
    left: 8px;
}

// Sidebar / Categories Nav

.sidebar {
    width: 25%;
    img {
        width: 120px;
        margin: 20px;
    }
}

#sidebar {
    display: inline-block;

    nav.categories {
        position: relative;
        background: $white;
        margin-top: -1.25rem;
        padding: 2.5rem 1.25rem 1rem;
        box-shadow: 0 0 0.65rem $grey;
        border-radius: 0 0 0.125rem 0.125rem;
        //z-index: 1;

        h4 {
            margin: 0 0 1.25rem;
            font-weight: 500;
        }

        ul {
            margin: 0;
            padding: 0;
            font-weight: 300;
            list-style: none;

            li {
                display: block;
                margin: 0 0 1.51rem;
                font-size: 0.85rem;

                &.active {
                    > a {
                        border-color: $primary;
                    }

                    > ul {
                        display: block;
                    }
                }

                a {
                    display: initial;
                    padding: 0.25rem 0;
                    border-bottom: 2px solid $white;
                    text-transform: uppercase;
                    text-decoration: none;
                    color: $black;

                    &:hover {
                        border-color: $primary;
                    }
                }

                ul {
                    display: none;
                    margin-top: 1.5rem;

                    &.active {
                        display: block;
                    }

                    li {
                        margin: 0 0 0.5rem 1rem;

                        a {
                            text-transform: capitalize;

                            &:hover {
                                border-bottom: 0 none;
                                color: $primary;
                            }

                            &.active {
                                border-color: $white;
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }

    .facebook {
        display: block;
        margin-top: 1rem;
        padding: 1rem 3.5rem;

        img {
            display: block;
            width: 100%;
            margin: 0 auto;
        }
    }
}


// Slider

.slider {
    width: 80%;
    .slick-dotted.slick-slider {
        margin-bottom: 0!important;
    }
}

#slider {
    .slide {
        display: flex;
        position: relative;

        .image {
            width: 100%;
            height: 496px;
            background: rgba(255,255,255,0);
            // background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,0) 0%, rgba(255,255,255,0.23) 56%, rgba(0,128,0,0.41) 100%);
            // background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(255,255,255,0)), color-stop(56%, rgba(255,255,255,0.23)), color-stop(100%, rgba(0,128,0,0.41)));
            // background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,0) 0%, rgba(255,255,255,0.23) 56%, rgba(0,128,0,0.41) 100%);
            // background: -o-radial-gradient(center, ellipse cover, rgba(255,255,255,0) 0%, rgba(255,255,255,0.23) 56%, rgba(0,128,0,0.41) 100%);
            // background: -ms-radial-gradient(center, ellipse cover, rgba(255,255,255,0) 0%, rgba(255,255,255,0.23) 56%, rgba(0,128,0,0.41) 100%);
            // background: radial-gradient(ellipse at center, rgba(255,255,255,0) 0%, rgba(255,255,255,0.23) 56%, rgba(0,128,0,0.41) 100%);
            // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#008000', GradientType=1 );

            img {
                position: absolute;
                text-align: center;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .content {
            position: absolute;
            padding: 0;
            text-align: center;
            right: 10%; top: 50%;
            transform: translateY(-50%);
            max-width: 300px;

            .off {
                text-align: center;
                margin: 0;
                font-size: 3em;
                color: white;
                text-transform: uppercase;
                font-weight: 500;
                text-shadow: 0px 0px 7px rgba($dark-grey, 1);
            }
            .name {
                margin: 0;
                color: $dark-grey;
                font-weight: 300;
            }
            .button {
                width: 150px;
                margin: 0 auto;
                margin-top: 20px;
            }

            .pricing {
                position: absolute;
                bottom: 2rem;
                width: 100%;

                .price {
                    display: inline-block;
                    position: relative;
                    margin: 0;
                    text-transform: uppercase;
                    vertical-align: bottom;
                    font-size: 0.75rem;
                    color: $dark-grey;
                    line-height: initial;

                    span {
                        padding-left: 0.5rem;
                        text-transform: none;
                        font-size: 1.5rem;
                        color: $black;

                        &.discount {
                            display: block;
                            position: relative;
                            top: 1rem; left: 9rem;
                            background: $highlight;
                            width: 3rem;
                            height: 3rem;
                            padding: 0.625rem 0.675rem 0.675rem 0.675rem;
                            border-radius: 50%;
                            text-transform: uppercase;
                            font-size: 0.7rem;
                            text-align: center;
                            color: $white;
                        }

                        em {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 2rem;
                            color: $highlight;

                            sup {
                                font-weight: 500;
                                font-size: 0.6rem;
                            }
                        }
                    }
                }

                a.button {
                    position: absolute;
                    right: 0; bottom: 0;
                }
            }
        }
    }

    ul.slick-dots {
        position: absolute;
        right: 10px; bottom: 5px;
        margin: 0;
        padding: 0;
        text-align: right;
        list-style: none;
        font-size: 0;

        li {
            display: inline-block;
            padding: 0 0 0 0.5rem;

            button {
                display: block;
                background: none;
                width: 0.75rem;
                height: 0.75rem;
                padding: 0;
                border: 1px solid $black;
                border-radius: 50%;
                text-indent: -999rem;
                text-align: left;
            }

            &.slick-active {
                button {
                    background: $black;
                }
            }
        }
    }
}


// Content

main {
    position: relative;
    background: $white;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -1rem;
        width: 100%;
        height: 1rem;
        box-shadow: 0 0.25rem 0.5rem $grey;
    }

    #page-header {
        background: transparent;
    }

    #callouts {
        background: $light-grey;
        padding: 3rem 1.25rem;
        text-align: center;

        ul {
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                width: 25%;
                text-transform: uppercase;
                text-align: center;

                i {
                    display: block;
                    background: no-repeat center center;
                    background-size: auto 60px;
                    width: 105px;
                    height: 105px;
                    margin: 0 auto 1rem;
                    border: 2px solid $primary;
                    border-radius: 50%;
                }

                &#nationwide-delivery {
                    i {
                        background-image: url('/img/icon-nationwide-delivery.png');
                    }
                }

                &#speedy-service {
                    i {
                        background-image: url('/img/icon-speedy-service.png');
                    }
                }

                &#wholesale-prices {
                    i {
                        background-image: url('/img/icon-wholesale-prices.png');
                    }
                }
            }
        }
    }

    .features {
        margin-top: 3rem;
        width: 90%;

        .slick-track{
            .slick-slide {
                height: auto;
                min-height: 285px;
            }
        }

        .slide {
            position: relative;
            margin: 1rem;
            box-shadow: 0px 0px 27px -6px rgba(35,35,35,0.14);

            .image {
                position: absolute;
                top: 0; bottom: 0;
                width: 50%;
                padding: 1rem;

                figure {
                    height: 100%;
                    margin: 0;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            .content {
                position: absolute;
                top: 0; right: 0; bottom: 0; left: 50%;
                padding: 1rem;
                text-align: left;

                h3 {
                    margin: 0 0 1rem;
                    font-size: 1rem;
                    font-weight: 400;
                    text-align: left;
                }

                p {
                    margin: 0;
                    font-size: 0.9rem;
                    color: $dark-grey;
                    line-height: 1.4em;

                    &.old-price,
                    &.new-price {
                        text-transform: uppercase;
                        padding: 0.25rem 0;
                        font-size: 0.8rem;

                        span {
                            float: right;
                            margin-top: -3px;
                            clear: right;
                        }

                        em {
                            display: inline-block;
                            vertical-align: middle;
                            font-size: 0.6rem;
                            font-style: normal;
                        }

                        strong {
                            display: inline-block;
                            vertical-align: middle;
                            font-size: 1rem;
                            font-weight: 400;
                        }
                    }

                    &.old-price {
                        margin-top: 0.5rem;
                    }

                    &.new-price {
                        strong {
                            color: $highlight;
                        }
                    }
                }

                a.button {
                    position: absolute;
                    bottom: 1rem;
                    left: 1rem;
                    font-size: 0.75rem;
                    &:hover {
                        background: $primary;
                        color: $white;
                        border-color: $primary;
                        transition: 0.5s;
                    }
                }
            }
        }
    }

    .offer-price-holder {
        margin-left: auto;
        margin-right: auto;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        font-size: 14px;
        color: #fff;
        line-height: 70px;
        text-align: center;
        background: #e51b25
    }

    .container {
        padding: 1.25rem;
    }

    #introduction {
        padding: 2rem 1.25rem;
    }

    .panel {
        padding: 2rem 1.25rem;
        text-align: center;

        h1 {
            position: relative;
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
            font-weight: 500;
            font-size: 112%;

            &:after {
                content: '';
                position: absolute;
                right: 0; bottom: 0; left: 0;
                background-color: $black;
                width: 3.5rem;
                height: 2px;
                margin: 0 auto;
            }
        }

        h2 {
            position: relative;
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
            font-weight: 500;
            font-size: 112%;

            &:after {
                content: '';
                position: absolute;
                right: 0; bottom: 0; left: 0;
                background-color: $black;
                width: 3.5rem;
                height: 2px;
                margin: 0 auto;
            }
        }

        h3 {
            position: relative;
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 112%;

            &:after {
                content: '';
                position: absolute;
                right: 0; bottom: 0; left: 0;
                background-color: $black;
                width: 3.5rem;
                height: 2px;
                margin: 0 auto;
            }
        }

        p {
            margin-bottom: 1.5rem;
            line-height: 1.5;
            font-size: 100%;
            color: $dark-grey;
        }
    }

    #contact {
        text-align: left;

        .contact-text {
            padding-right: 7.5%;
        }

        .contact-form {

            label {
                display: none;
            }

            .recaptcha {
                float: left;
            }

            .g-recaptcha {
                padding-bottom: 2rem;
            }

            .buttons {
                margin: 0;
                width: 100%;
            }
        }

        h3 {
            padding-bottom: 0;
            text-align: left;

            &:after {
                content: none;
            }
        }

        p {
            font-size: 100%;
        }

        .buttons {
            button, .button {
                padding: 0.75rem 2rem;
            }
        }
        #contact_message {
            margin-bottom: 1rem;
        }
        .g-recaptcha {
            padding-bottom: 1rem!important;
        }
    }

    // header + #stockists,
    // #content + #stockists,
    // .content + #stockists {
    // background: $light-grey;
    // }

    #stockists {
        ul {
            margin: 0 0 1rem;
            padding: 0;

            li {
                display: inline-block;
                margin: 0 3rem;
                vertical-align: top;

                a {
                    display: block;
                    width: 100%;

                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }

    #account {
        padding: 5rem 1rem;

        #account-nav {
            float: left;
            width: 20%;
            margin: 0 5% 0 0;
            padding: 0;
            vertical-align: top;

            h3 {
                display: inline-block;
                width: auto;
                margin: 0 0 1.5rem;
                padding: 0;
                text-align: left;
                font-size: 1.1rem;
                font-weight: 400;
            }

            ul {
                margin: 0 0 1rem;
                padding: 0;
                border-radius: 2px;

                li {
                    display: block;
                    border-top: 1px solid rgba($grey, 0.3);
                    list-style: none;

                    &:first-child {
                        border-top: 0 none;

                        a {
                            border-top-right-radius: 2px;
                            border-top-left-radius: 2px;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom-right-radius: 2px;
                            border-bottom-left-radius: 2px;
                        }
                    }

                    &.logout {
                        float: none;
                        width: 100%;
                        padding: 2rem 0 0 0;
                        border: 0 none;
                        font-size: 90%;

                        a {
                            background-color: $negative;
                            padding: 0.5rem 1rem;
                            text-align: center;
                            border-radius: 4px;
                            color: $white;
                        }
                    }

                    a {
                        display: block;
                        padding: 0.75rem 0.5rem;
                        text-decoration: none;
                        color: $dark-grey;

                        &:hover,
                        &.active {
                            color: lighten($primary, 15%);
                        }
                    }
                }
            }
        }

        #account-content {
            float: left;
            width: 75%;
            margin: 0;
            padding: 0;
            vertical-align: top;

            h1 {
                margin-top: 0;
            }

            .empty {
                //font-style: italic;
                text-align: center;
            }

            #edit-account-details {
                fieldset {
                    margin-top: 1rem;
                }
            }
        }

        #edit-account-details {
            .col1of1,
            .col1of2 {
                padding: 0 0.5rem;
            }

            .buttons {
                text-align: right;

                button {
                    margin-right: 0.5rem;
                }
            }
        }

        #update-password {
            .buttons {
                text-align: right;
            }
        }
    }
}


// Products

#products {
    #sidebar {
        vertical-align: top;
    }
}

.product-list {
    width: 75%;
}

#product-list {
    display: inline-block;
    margin: 0;
    vertical-align: top;
    text-align: center;
    width: 100%;

    .product-container {
        display: inline-block;
        position: relative;
        background: $white;
        width: 30%;
        margin: 8px 6px;
        padding: 1rem;
        vertical-align: top;
        text-align: center;
        list-style: none;
        box-shadow: 0px 0px 27px -6px rgba(35,35,35,0.14);

        a {
            display: block;
            width: 100%;
            padding: 1rem;
            text-decoration: none;
            color: lighten($dark-grey, 10%);

            span {
                padding-left: 0.5rem;
                text-transform: none;
                font-size: 1.5rem;
                color: $black;

                &.discount {
                    display: block;
                    position: absolute;
                    top: 1rem; right: 1rem;
                    background: $highlight;
                    width: 3rem;
                    height: 3rem;
                    padding: 0.625rem 0.675rem 0.675rem 0.675rem;
                    border-radius: 50%;
                    text-transform: uppercase;
                    font-size: 0.7rem;
                    text-align: center;
                    color: $white;
                }
            }

            img {
                display: block;
                width: 100%;
            }

            .description {
                margin-top: 1rem;
                font-size: 0.9rem;

                p {
                    margin: 0;
                    font-weight: 400;

                    &.price {
                        margin-top: 1rem;
                        color: $primary;

                        &.discount {
                            color: $highlight;
                        }

                        small {
                            display: inline-block;
                            text-transform: uppercase;
                            vertical-align: middle;
                            font-size: 0.6rem;
                            color: lighten($dark-grey, 10%);
                        }
                    }
                }
            }
        }
    }
}


// Product

#product {

    .product-feature {
        width: 75%;
    }
    #product-feature {
        display: inline-block;
        width: 100%;
        vertical-align: top;
        padding-left: 10px;

        .text-head {
            padding: 1.25rem 0 0 2.75rem;

            .breadcrumbs {
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                list-style: none;
                font-size: 0.85rem;

                li {
                    display: inline;
                    color: $grey;

                    &.current {
                        color: $black;
                    }

                    a {
                        text-decoration: none;
                        color: $grey;
                    }
                }
            }

            .product-code {
                margin: 0.75rem 0 1.5rem;
                font-size: 0.8rem;
                color: rgba($dark-grey, 0.75);
            }

            h1 {
                margin-bottom: 0;
            }
        }

        #product-slider {
            display: inline-block;
            width: 79%;
            vertical-align: middle;

            .slide {
                margin: 0;

                img {
                    width: auto;
                    max-width: 100%;
                    max-height: 430px;
                    margin: 0 auto;
                }
            }

            .slick-arrow {
                width: 2rem;
                height: 2rem;
                margin: -1rem 0 0 0;
                z-index: 2;

                &.slick-prev {
                    left: 2rem;
                    background-image: url('/img/arrow-left.png');
                }

                &.slick-next {
                    right: 2rem;
                    background-image: url('/img/arrow-right.png');
                }
            }
        }

        #product-slider-nav {
            display: inline-block;
            width: 20%;
            vertical-align: middle;

            .slick-prev {
                top: 0; left: 50%;
                margin: 0;
                transform: rotate(90deg) translate(-100%, 50%);
            }

            .slick-next {
                top: auto; bottom: 0; left: 50%;
                margin: 0;
                transform: rotate(90deg) translate(100%, 50%);
            }

            .slick-slide {
                width: 7.5rem;
                margin: 0;
                padding: 0.25rem;

                &:focus {
                    outline: none;
                }

                &.slick-current {
                    opacity: 0.5;
                }

                img {
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }

    #product-options {
        position: relative;
        background-color: $light-grey;
        padding: 1rem;

        fieldset {
            display: inline-block;
            padding:  0.5rem;
            vertical-align: middle;
            text-align: left;

            label {
                margin: 0 0 0.25rem;
                padding: 0;
                text-transform: uppercase;
                font-weight: 300;
            }

            input {
                margin: 0;
            }

            select {
                width: 100%;
                height: auto;
                margin: 0;
            }

            &.buttons {

                button, .button {
                    margin: 0;
                }
            }
            .addtocart {
                margin-top: 5px!important;
            }
        }

        #cart-success-message {
            display: block;
            position: absolute;
            top: 50%; left: 50%;
            background-color: $positive;
            width: 100%;
            padding: 2.5rem 2rem;
            transform: translate(-50%, -50%);
            opacity: 1;
            transition: 1s;
            transition-timing-function: ease-in;
            color: $white;
            z-index: 2;

            &.hidden {
                display: none;
            }

            &.hiding {
                opacity: 0;
                transition: 1s;
                transition-timing-function: ease-in;
            }

            p {
                margin: 0;
                color: $white;
            }
        }
    }

    .product-details {
        background: none;
        padding-top: 1rem;
        text-align: left;

        .row {
            max-width: 800px;
            padding: 2rem 0;
            border-bottom: 1px solid $grey;

            &:last-child {
                border: none;
            }

            table {
                width: 100%;
            }

            dl {
                dt {
                    float: left;
                    display: block;
                    width: 185px;
                    padding: 0.25rem 0;
                    clear: left;
                }

                dd {
                    float: left;
                    display: block;
                    padding: 0.25rem 0;
                }
            }
        }

        .product-description {
            padding-bottom: 1rem;
            font-size: 0.9rem;
        }

        .specs {
            overflow: hidden;
            font-size: 0.8rem;

            tr:nth-of-type(even) {
                background-color: rgba($light-grey, 0.5);
            }

            th {
                background: none;
            }

            td {
                border: 0 none;
            }
        }

        table {
            th, td {
                padding: 0.65rem 1rem;
            }

            th {
                background: $light-grey;
            }

            tbody {
                td {
                    border-right: 1px solid $light-grey;
                    text-transform: uppercase;

                    &:last-child {
                        border: none;
                    }

                    .onsale {
                        display: block;
                        font-size: 0.65rem;
                        clear: both;

                        &.discount {
                            color: $highlight;
                        }
                    }

                    .newprice {
                        color: $highlight;

                        small {
                            font-size: 0.65rem;
                        }
                    }
                }
            }
        }
    }

    #related-products {
        background: $light-grey;

        h3 {
            margin: 0 0 2.5rem;
            padding-bottom: 1rem;
            font-size: 1rem;
        }

        ul {
            margin: 0;
            padding: 0;
            text-align: center;

            li {
                display: inline-block;
                background: $white;
                width: 23%;
                margin: 0.5rem;
                padding: 1rem;
                vertical-align: top;
                list-style: none;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);

                a {
                    display: block;
                    width: 100%;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-size: 0.85rem;
                    color: $dark-grey;

                    img {
                        display: block;
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}


// Cart

#shopping-cart {
    #cart {
        background: none;
        padding: 4.5rem 1.25rem;

        h3 {
            position: relative;
            margin-bottom: 2rem;
            padding-bottom: 1.1rem;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 1.5rem;

            &:after {
                content: '';
                position: absolute;
                right: 0; bottom: 0; left: 0;
                background-color: $black;
                width: 3.5rem;
                height: 2px;
                margin: 0 auto;
            }
        }

        .buttons {
            margin-top: 2rem;
            text-align: right;

            button,
            .button {
                display: inline-block;
            }
        }

        &.removing {
            animation: unpop .25s forwards;
        }
    }
}

#cart-table {
    width: 100%;

    thead {
        th {
            border-bottom: 1px solid $light-grey;
            text-transform: uppercase;
            font-weight: 300;
        }
    }

    tbody {
        tr:last-child {
            td {
                border-bottom: 1px solid $light-grey;
            }

            &.empty {
                td {
                    border-bottom: 0 none;
                }
            }
        }
    }

    tfoot {
        text-transform: uppercase;
        font-size: 0.9rem;

        tr {
            position: relative;
            &.coupon {
                color: $red;
            }
        }

        .shipping {
            td {
                padding-top: 1.5rem;
            }
        }

        .gst {
            td {
                padding-bottom: 1.5rem;
            }
        }

        .total {
            td {
                padding-top: 1.5rem;
            }
        }

        td {
            padding: 0.5rem 1rem;
        }

        td.label {
            text-align: right;
        }

        td.amount {
            text-align: right;
        }

        tr.total {
            td.label, td.amount {
                border-top: 1px solid $light-grey;
                font-weight: 600;
            }
        }
    }

    td, th {
        padding: 1rem;
        text-align: center;

        &.delete {
            width: 2rem;
            padding: 0.65rem;
            text-align: left;

            a.button {
                background: url('/img/btn-delete.png') no-repeat center center;
                background-size: 36%;
                width: 30px;
                height: 30px;
                padding: 0;
                line-height: 30px;
                border-radius: 50%;
                border-color: $grey;
                text-indent: -999rem;

                &:hover {
                    border-color: $black;
                }

                &.loading {
                    background-image: url('/img/spinner-loading.gif');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: auto 80%;
                    color: rgba(0, 0, 0, 0);
                }
            }
        }

        &.product-image {
            width: 115px;
            text-align: left;

            img {
                display: block;
                max-width: 115px;
                max-height: 115px;
                margin: 0 auto;
            }
        }

        &.product {
            text-align: left;

            .product-code {
                font-size: 0.7rem;
                color: $grey;
            }

            .product-name {
                margin: 1rem 0 0;
                text-transform: uppercase;
                font-weight: 400;
                font-size: 1rem;
            }

            .product-option {
                font-size: 0.85rem;
            }
        }

        &.qty {
            width: 130px;

            input {
                width: 6rem;
                margin-bottom: 0;
            }

            .updowner {
                margin-left: 0;
            }
        }

        &.total, &.price {
            text-align: right;
        }
    }
}

#coupon-form {
    float: left;
    margin-top: -42px;
    #coupon-code {
        width: 200px;
        height: 41px;
    }
    button {
        display: initial;
        padding: 0.75rem 1rem;
    }
}


// Footer

footer {
    padding: 3.5rem 1.25rem 1.5rem 1.25rem;
    font-size: 0.765rem;
    text-align: center;
    color: $grey;
    line-height: 2em;
    background: $charcoal;
    z-index: 10;

    ul {
        margin: 0 0 2rem;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            width: auto;
            margin: 0 1rem;
            clear: none;

            a {
                text-transform: uppercase;
                text-decoration: none;
                font-size: 0.85rem;
                font-weight: 200;
                color: $white;
            }
        }
    }

    span {
        display: inline-block;
        margin:  0 0.5rem;

        &.credit {
            a {
                color: $grey;
            }
        }
    }
}

.pagination {
    margin: 0;
    padding: 0;
    text-align: center;
    width: 75%;
    float: right;
    padding: 20px;

    li {
        display: inline-block;
        width: auto;
        margin: 0 0.25rem;
        padding: 0;
        text-align: center;
        list-style: none;
        font-size: 80%;

        &.disabled {
            span {
                background: lighten($light-grey, 20%);
                color: $grey;
            }
        }

        &.active {
            span {
                background: lighten($primary, 40%);
                border-color: lighten($primary, 20%);
                color: $primary;
            }
        }

        span {
            display: block;
            background-clip: padding-box;
            width: 2rem;
            height: 2rem;
            padding: 0.5rem 0 0.5rem 0.1rem;
            border: 1px solid transparent;
            border-radius: 100%;
            line-height: 1.1;
        }

        a {
            display: block;
            background-clip: padding-box;
            width: 2rem;
            height: 2rem;
            padding: 0.5rem 0 0.5rem 0.1rem;
            border: 1px solid rgba(lighten($grey, 10%), 0.66);
            text-decoration: none;
            border-radius: 100%;
            line-height: 1.1;
            color: $dark-grey;

            &:hover {
                background-color: lighten($grey, 20%);
                border-color: lighten($grey, 10%);
                color: $dark-grey;
            }
        }
    }
}

// Page Specific

body#home {
    .home-container {
        padding-top: 0;
    }
    .sidebar {
        padding-top: 1.25rem;
        width: 20%;
    }
    #slider {
        display: inline-block;
        width: 100%;
    }

    #quick-search {
        margin: 1rem auto 4rem;
        border-bottom: 2px solid $grey;
        text-align: center;

        ul {
            display: inline-block;
            position: relative;
            top: 1.2rem;
            width: 100%;
            margin: 0 auto;
            padding: 0;
            text-align: center;
            list-style: none;

            li {
                display: inline-block;
                background: $white;
                width: 25%;
                padding: 0 0.5rem;

                a {
                    display: block;
                    background: $dark-grey;
                    border-color: $dark-grey;
                    &:hover {
                        border-color: $primary;
                        background: $primary;
                        transition: 0.5s;
                    }
                }
            }
        }
    }

    .panel {
        &:nth-child(odd) {
            background: $light-grey;
        }
    }

    h1 {
        border-bottom: 0 none;
    }
}

#checkout-detail-ordersummary {
    text-align: left;
    clear: left;

    p {
        font-size: 1rem;
    }
}

#shipping-address {
    .note {
        padding: 0.25rem 0 0.2rem 0.6rem;
        margin: 0;
        font-size: 0.8em;
        text-align: left;
    }
    h4 {
        margin: 0.75rem 0 0 0;
    }
}

#checkout-detail-form {
    float: left;
    text-align: left;

    #credit-card-type {
        margin-bottom: 1rem;
        padding: 0.5rem 0 0;
        clear: left;

        label:not(:first-of-type) {
            margin-left: 1rem;
        }

        input {
            margin: 0.1rem 0.5rem;
        }

        .secure-logos {
            float: right;
            margin-top: -5px;
            img {
                width: 80px;
                margin: 0 15px;
            }
        }
    }

    #exp-month {
        display: inline-block;
        width: 45%;
        margin-right: 1rem;
        clear: none;
    }

    #exp-year {
        display: inline-block;
        width: 45%;
        clear: none;
    }
}

#checkout-register {
    text-align: center;

    h1 {
        margin-top: 0;
    }

    .col1of1 {
        padding: 0;
    }

    .member {
        display: inline-block;
        border-right: 1px solid #ccc;
        vertical-align: top;
        text-align: left;
        margin: 50px 0;
        padding: 50px;

        label {
            width: 100%;

            a {
                float: right;
                margin-top: 2px;
                text-decoration: none;
                font-weight: 300;
                font-size: 80%;
            }
        }

        button {
            display: inline-block;
            clear: left;
        }

        .nav {
            float: right;
            margin: 0.5rem 1rem;
            font-size: 80%;
        }
    }

    .guest {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        margin: 50px 0;
        padding: 50px;
    }
}

#shipping {
    .buttons {
        button {
            display: inline-block;
            float: right;
        }
    }
}

#order-received {
    margin-bottom: 3rem;
    padding: 3rem 0;
    border-bottom: 2px solid $dark-grey;
    text-align: center;
}

#checkout-complete {
    #order-summary {
        max-width: 60rem;
        margin: 0 auto 5rem;
    }
}

#order-history {

    tbody {
        tr {
            &:hover {
                background: $light-grey;
            }
        }
    }

    tr {
        th:first-child,
        td:first-child {
            padding-left: 0.5rem;
        }
    }

    th {
        padding: 0.75rem 0.5rem;
        text-align: left;
    }

    td {
        padding: 0.75rem 0.5rem;
        border-top: 1px solid $light-grey;
        font-size: 0.9rem;
    }

    .view {
        padding: 0.5rem;
        text-align: center;

        a {
            display: block;
            padding: 0.5rem 0.5rem;
            text-decoration: none;

            &:hover {
                background: $primary;
                color: $white;
            }
        }
    }
}

#order-summary-products {
    margin: 1rem 0;
    padding-bottom: 3rem;
    border-bottom: 1px solid $grey;
}

#order-summary-status {
    float: left;
    display: block;
    width: 33.33%;
    font-size: 0.9rem;
    line-height: 1.6;

    dt {
        float: left;
        display: block;
        width: 7rem;
        font-weight: 500;
        clear: left;
    }

    dd {
        float: left;
        display: block;
        margin-left: 1rem;
    }
}

#order-summary-shipping {
    float: left;
    display: block;
    width: 33.33%;
    font-size: 0.9rem;
    line-height: 1.6;
}

#order-summary-billing {
    float: left;
    display: block;
    width: 33.33%;
    font-size: 0.9rem;
    line-height: 1.6;
}


// Updowner

.updowner {
    display: inline-block;
    margin-left: 2rem;
    border-radius: 0.35rem;
    border: 1px solid $grey;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;

    input {
        display: none !important;
    }

    a.updowner-switch {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
        vertical-align: middle;
        text-decoration: none;
        line-height: 1;
        color: $dark-grey;

        &.updowner-down {
            border-right: 1px solid $grey;
        }

        &.updowner-up {
            border-left: 1px solid $grey;
        }

        &.inactive {
            cursor: default;
            color: $light-grey;

            &:hover {
                background: $white;
            }
        }

        &:hover {
            background: lighten($light-grey, 20%);
            text-decoration: none;
        }
    }

    .updown_product_quantity {
        word-spacing: -3px;
    }

    span.updowner-output {
        display: inline-block;
        width: 2.25rem;
        height: 2rem;
        padding: 0.5rem;
        vertical-align: middle;
        cursor: default;
        line-height: 1;
    }
}

// About Us

#about-us {
    p {
        line-height: 1.5em;
        font-size: 0.9em;
    }
    h2 {
        padding-top: 8px;
    }
}

.notice-container {
    background: $light-grey;
    .row {
        padding: 0 1rem;
    }
    .notice {
        display: inline-block;
        p {
            display: inline-block;
            font-size: 0.9em;
            max-width: 530px;
            b {
                font-weight: 400;
                color: $primary;
            }
        }
        img {
            display: inline-block;
            margin-right: 1em;
            width: 40px;
            height: 40px;
            vertical-align: text-bottom;
        }
    }
}

#cart, #checkout {
    .notice-container.bottom {
        margin-top: 1em;
    }
}


// Responsive

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1177px) {
    #sidebar nav.categories ul li {
        font-size: 0.84rem;
    }
}

@media only screen and (max-width: 1140px) {
    .slider {
        .pricing {
            position: initial!important;
            a {
                position: initial!important;
                margin-top: 10px;
            }
        }
        .content {
            padding-left: 10px!important;
        }
    }
    main .features .slide .content p {
        font-size: 0.8rem!important;
    }
}

@media only screen and (max-width: 1024px) {
    .sidebar, .slider {
        width: 100%!important;
    }
    .sidebar {
        #sidebar {
            width: 100%;
        }
        .categories {
            ul {
                -moz-column-count: 2;
                -moz-column-gap: 2.5em;
                -webkit-column-count: 2;
                -webkit-column-gap: 2.5em;
                column-count: 2;
                column-gap: 2.5em;
            }
        }
    }
}

@media only screen and (max-width: 940px) {
    header {
        #free-shipping {
            right: 35%;
        }
    }
}

@media only screen and (max-width: 880px) {
    #quick-search {
        border-bottom: none!important;
        ul li {
            width: 33.3%!important;
            a {
                font-size: 0.8em;
            }
        }
    }
    #callouts {
        ul li {
            width: 33.3%!important;
        }
    }
}

@media only screen and (max-width: 850px) {
    header .logo {
        width: 200px;
    }
}

@media only screen and (max-width: 840px) {
    #related-products {
        ul li {
            width: 47%!important;
        }
    }
    #slider {
        .slide {
            .image {
                height: 280px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .content {
                .off {
                    font-size: 2em;
                }
                .name {
                    margin: 0;
                    color: $dark-grey;
                    font-weight: 300;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    header {
        z-index: 4;
        position: fixed;
        width: 100%;
        box-shadow: 0 0 0.65rem $grey;
        .row {
            padding: 0;
        }
        .logo {
            height: 60px;
            width: 165px;
            margin-left: 20px;
        }
        .nav-collapse {
            display: block;
        }
        #account, #logout {
            display: none;
        }
    }
    #free-shipping {
        display: none!important;
    }
    .tools-nav {
        .search, #login {
            display: none;
        }
        #cart-icon {
            position: absolute;
            top: 17px;
            right: 75px;
            //z-index: 3;
        }
    }
    .site-nav, .navigation-full-size {
        display: none!important;
    }
    .site-nav-collapse {
        display: block;
        height: 0;
        ul li {
            display: block;
            text-align: center;
            a {
                margin: 0 auto;
            }
        }
        .tools-nav-collapse {
            float: none;
            padding-top: 0;
            width: 100%;
            ul li {
                margin: 0 auto!important;
                max-width: none!important;
                &.search {
                    a {
                        background-size: 25px!important;
                    }
                    &.active {
                        right: 50%;
                        transform: translateX(50%);
                        input {
                            right: 50%;
                            transform: translateX(50%);
                        }
                        button {
                            display: none;
                        }
                    }
                }
                &#cart-icon {
                    a {
                        background-size: 25px!important;
                    }
                }
            }
        }
    }
    #products {
        .product-list {
            width: 100%;
        }
        .pagination {
            width: 100%;
        }
    }
    #product {
        .product-feature {
            width: 100%;
            padding-top: 20px;
            #product-feature {
                padding-left: 0!important;
            }
        }
    }
    #checkout-register {
        .member, .guest {
            padding: 0;
            margin: 20px 0;
            border-right: 0;
        }
    }
    #reset-password {
        form {
            margin: 0 auto;
            padding: 30px;
        }
    }
    #register {
        form {
            margin: 0 auto;
            padding: 30px;
        }
    }
    #login {
        form {
            margin: 0 auto;
            padding: 30px;
        }
    }
    main #account {
        padding: 2rem 1rem!important;
        #account-nav {
            float: none!important;
            width: 70%!important;
            margin: 0 auto!important;
        }
        #account-content {
            width: 100%!important;
            padding: 20px!important;
            #order-history {
                overflow: auto;
            }
        }
    }
    .notice-container {
        margin-top: 60px;
        .notice {
            max-width: 470px;
            img {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .slider {
        .content {
            .description {
                display: none;
            }
        }
    }
    #shopping-cart, #view-order {
        #cart-table {
            display: block;
            thead {
                display: none;
            }
            tbody {
                display: table;
                margin: 0 auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0 20px;
                td {
                    display: block;
                    width: 100%;
                }
            }
            tfoot {
                display: table;
                width: 100%;
            }
        }
        #cart, #account {
            #cart-table {
                border: none;
                tbody {
                    tr {
                        background: $white;
                        box-shadow: 0px 0px 27px -6px rgba(35,35,35,0.14);
                    }
                    .qty {
                        float: right;
                        input {
                            float: right;
                        }
                    }
                    .total {
                        float: right;
                    }
                    .product-image {
                        img {
                            max-width: none;
                            max-height: none;
                            width: 100%;
                        }
                    }
                }
            }
            table, tr, td {
                border-bottom: none!important;
            }
            #account-content {
                padding: 10px!important;
            }
        }
    }
    #coupon-form {
        float: right;
        margin-top: -100px;
    }
    #cart {
        .buttons {
            float: right;
            width: 100%;
            margin-top: 5rem!important;
        }
    }
}

@media only screen and (max-width: 650px) {
    #home #quick-search {
        border-bottom: none!important;
        ul li {
            display: block;
            width: 100%!important;
            margin-top: 5px;
        }
    }
    main {
        .features {
            width: 80%!important;
            margin-top: 2rem;
            .content {
                font-size: 0.9em!important;
                position: initial!important;
                padding: 0 1rem 4rem 1rem!important;
            }
            .image {
                position: initial!important;
                width: 100%!important;
                img {
                    margin: 0 auto;
                }
            }
        }
        #introduction {
            padding: 2rem 1.25rem;
        }
        .panel {
            padding: 2rem 1.25rem;
        }
    }
    #product {
        #product-options {
            fieldset {
                display: block;
            }
        }
    }
    #cart-success-message {
        padding: 7rem 2rem!important;
    }
    #slider {
        .slide {
            .image {
                height: auto;
                padding: 0;
                img {
                    margin: 0 auto;
                    position: initial;
                    transform: initial;
                }
            }
            .content {
                margin: 12px auto 0 auto;
                position: initial;
                transform: initial;
                .off {
                    font-size: 2em;
                }
                .name {
                    margin: 0;
                    color: $dark-grey;
                    font-weight: 300;
                }
                .button {
                    margin-top: 12px;
                }
            }
        }
        .slick-dots {
            display: none!important;
        }
    }
}

@media only screen and (max-width: 610px) {
    #callouts {
        ul li {
            width: 100%!important;
            margin: 20px 0;
        }
    }
    .product-details {
        table {
            font-size: 80%;
            th, td {
                padding: 0.5rem 0.8rem!important;
            }
        }
    }
    #product-list {
        .product-container {
            width: 47%;
        }
    }
}

@media only screen and (max-width: 572px) {
    #related-products {
        ul li {
            width: 100%!important;
            margin: 5px 0!important;
        }
    }
}

@media only screen and (max-width: 520px) {
    #slider .slide {
        display: block!important;
        .image, .content {
            width: 100%;
        }
    }
    footer {
        ul li {
            display: block;
        }
    }
    #product {
        #product-slider-nav {
            display: none!important;
        }
        #product-slider {
            width: 100%!important;
            .slick-arrow.slick-prev {
                left: 0.5rem!important;
            }
            .slick-arrow.slick-next {
                right: 0.5rem!important;
            }
        }
        .text-head {
            padding: 1.25rem 0 0 0!important;
        }
    }
    #product-list {
        .product-container {
            width: 100%;
            margin: 8px 0;
        }
    }
}

@media only screen and (max-width: 450px) {
    .sidebar {
        text-align: center;
        .categories {
            ul {
                -moz-column-count: 1;
                -webkit-column-count: 1;
                column-count: 1;
            }
        }
    }
    #coupon-form {
        #coupon-code {
            width: 124px;
        }
    }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}

// View full / responsive site

span#view-responsive {
    display: none;
}
span#view-responsive, span#view-full {
    &:hover {
        color: $primary;
    }
}
@media screen and (min-width: 800px) {
    span#view-full {
        display: none;
    }
    span#view-responsive {
        display: inline;
    }
}

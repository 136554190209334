$primary: #60c421;
// $secondary: ;
// $tertiary: ;
$highlight: #ed1d25;
$link: #7ac143;
$green: #008000;
$red: #e51b25;

$positive: #7ED648;
$negative: #e64747;

$white: #ffffff;
$light-grey: #f3f3f3;
$grey: #c2c2c2;
$dark-grey: #555555;
$charcoal: #363636;
$black: #232323;
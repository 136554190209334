@import "_colours.scss";

form {
    *:focus {
        outline: none;
    }

    .full {
        float: left;
        width: 100%;
    }

    .half {
        float: left;
        width: 50%;
        padding: 0 1rem;

        &:nth-of-type(2n) {
            padding-right: 0;
        }

        &:nth-of-type(2n+1) {
            padding-left: 0;
        }

        &.offset-right {
            margin-right: 50%;
        }

        &.offset-left {
            margin-left: 50%;
        }
    }

    fieldset {
        margin: 0;
        padding: 0;
        border: 0 none;

        &.radiochecks {
            padding: 0.5rem;

            label {
                float: left;
                position: relative;
                display: inline-block;
                margin-bottom: 1rem;
                padding-left: 1.75rem;
                font-weight: 300;
                clear: left;
            }

            input {
                display: inline-block;
                position: absolute;
                left: 0;
                width: auto;
                margin: 0.25rem 0.5rem;
                clear: left;
            }
        }

        &.inline {
            label {
                float: none;
                display: inline-block;
            }

            input {
                float: none;
                display: inline-block;
                clear: none;
            }
        }

        &.buttons {
            margin-top: 1rem;

            button, .button {
                float: left;
                height: auto;
                letter-spacing: 0;
                font-weight: 400;
                line-height: 1;
            }
        }

        legend {
            margin: 0 0 0.5rem;
            font-weight: 100;
            font-size: 150%;
        }
    }

    .col1of1 {
        float: left;
        width: 100%;
        padding: 0 0.5rem;
    }

    .col1of2 {
        padding: 0 0.5rem;
    }

    label {
        float: left;
        display: block;
        margin: 0 0 0.25rem;
        padding: 0;
        text-transform: none;
        font-size: 90%;
        clear: left;

        &.req:after {
            content: " *";
        }
    }

    input[type] {
        float: left;
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        padding: 0.5rem 0.75rem;
        border-radius: 2px;
        font-size: 90%;
        color: $dark-grey;
        clear: left;

        &.datepicker {
            text-align: right;
        }

        &.error {
            margin-bottom: 0;
        }
    }

    textarea {
        float: left;
        display: block;
        width: 100%;
        height: 10rem;
        margin-bottom: 2rem;
        padding: 0.8rem 0.75rem;
        border-radius: 2px;
        border: 1px solid $grey none;
        font-size: 90%;
        color: $dark-grey;
        clear: left;

        &.error {
            margin-bottom: 0;
        }
    }

    select {
        float: left;
        display: block;
        background: $white url('/img/arrow.gif') no-repeat;
        background-position: right 0.75rem top 50%;
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
        padding: 0.5rem 3rem 0.5rem 0.6rem;
        text-overflow: ellipsis;
        border-radius: 2px;
        font-size: 90%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: $dark-grey;
        clear: left;

        &.error {
            margin-bottom: 0;
        }
    }

    select::-ms-expand {
    display: none;
    }

    input[disabled],
    select[disabled],
    textarea[disabled] {
        border-color: $light-grey;
    }

    button,
    a.button {
        margin-right: 0.5rem;
        padding: 0.5rem 1rem;
    }

    &.auth {
        max-width: 30rem;
        margin: 10rem auto;
        color: $black;

        p {
            margin: 0 0 1rem;
        }

        fieldset {
            &.radiochecks {
                label {
                    padding-left: 1.75rem;
                }
            }

            label {
                width: 100%;
                margin: 0 0 0.25rem;
                padding: 0;

                &[for="password"] {
                    a {
                        float: right;
                        margin-top: 2px;
                        text-decoration: none;
                        font-weight: 300;
                        font-size: 80%;
                    }
                }

                .forgot {
                    float: right;
                }
            }

            input,
            select,
            textarea {
                margin-bottom: 1rem;
                color: $black;
            }

            button {
                display: inline-block;
                padding: 0.5rem 1.25rem;
            }

            .nav {
                float: right;
                margin: 0.5rem 1rem;
                font-size: 80%;
            }
        }

        section {
            margin-bottom: 2rem;
            clear: both;
        }
    }

    .formtext {
        float: left;
        width: 100%;
        margin: 0 0 2rem;
        padding: 0.75rem 0.6rem;
        text-align: left;
        font-size: 90%;
        border: 1px solid transparent;
    }

    &#register-form {
        max-width: 600px;
    }
}
// FontFamily - Montserrat
// ===================================================================================================================
@import "_montserrat/montserrat";

// Buttons and Links that look them
// ===================================================================================================================
.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  height: 2.6rem;
  padding: 0 2rem;
  color: #555;
  text-align: center;
  font-size: 0.865rem;
  font-weight: 600;
  line-height: 2.6rem;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 0.25rem;
  border: 1px solid #bbb;
  cursor: pointer;
  box-sizing: border-box;
  &:hover, &:focus {
    color: #333;
    border-color: #888;
    outline: 0;
  }
}

// Form Elements
// ===================================================================================================================
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  height: 38px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #fff;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
}
/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border: 1px solid #33C3F0;
  outline: 0;
}
label,
legend {
  display: block;
  margin-bottom: .5rem;
  font-weight: 600;
}
fieldset {
  padding: 0;
  border-width: 0;
}
input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

// Grid layout
// ===================================================================================================================
body, html {
  width: 100%;
  height: 100%;
  font-size: 16px;
  * {
    box-sizing: border-box;
  }
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.row {
  *zoom: 1;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}
.row:after,
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  clear: both;
  visibility: hidden;
}
.col1of2, .col2of4, .col3of6 {
  width: 50%;
}
.col1of3, .col2of6 {
  width: 33.33333%;
}
.col2of3, .col4of6 {
  width: 66.66666%;
}
.col1of4 {
  width: 25%;
}
.col3of4 {
  width: 75%;
}
.col1of5 {
  width: 20%;
}
.col2of5 {
  width: 40%;
}
.col3of5 {
  width: 60%;
}
.col4of5 {
  width: 80%;
}
.col1of6 {
  width: 16.66666%;
}
.col5of6 {
  width: 83.33333%;
}
.col1of2,
.col1of3, .col2of3,
.col1of4, .col2of4, .col3of4,
.col1of5, .col2of5, .col3of5, .col4of5,
.col1of6, .col2of6, .col3of6, .col4of6, .col5of6 {
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0;
  @media (max-width: 768px) {
    float: none;
    width: 100%;
    clear: both;
  }
}
